import { Button } from 'components/ui/button';
import { DialogDescription, DialogFooter, DialogHeader, DialogTitle } from 'components/ui/dialog';
import { Label } from 'components/ui/label';
import { Slider } from 'components/ui/slider';
import { useState } from 'react';
import Cropper from 'react-easy-crop';
import getCroppedImg, { resizeImage } from './cropImageHelpers';
import {
  TRANSIENT_RESERVATIONS_IMAGE_RESOLUTIONS_TO_SAVE,
  TRANSIENT_RESERVATIONS_PHOTO_ASPECT_RATIO,
  TR_PHOTO_USER_PREVIEW_HEIGHT,
  TR_PHOTO_USER_PREVIEW_WIDTH
} from './sharedvariables';

const uuid = require('uuid');

export default function ImageCropper({ photoFile, uploadPhotosToCloud, isUploading }) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const onCropClicked = async () => {
    try {
      const name = uuid.v4();
      const croppedImageOriginalResolution = await getCroppedImg(photoFile.preview, croppedAreaPixels);
      const imagesToSave = [
        { file: photoFile, name, resolution: 'original' },
        { file: croppedImageOriginalResolution, name, resolution: 'originalCropped' }
      ];
      for (const resizedResolutionHeight of TRANSIENT_RESERVATIONS_IMAGE_RESOLUTIONS_TO_SAVE) {
        const resizedResolutionWidth = Math.round(resizedResolutionHeight * TRANSIENT_RESERVATIONS_PHOTO_ASPECT_RATIO);
        const resizedImage = await resizeImage(croppedImageOriginalResolution, resizedResolutionWidth, resizedResolutionHeight);
        imagesToSave.push({ file: resizedImage, name, resolution: `${resizedResolutionWidth}x${resizedResolutionHeight}` });
      }
      uploadPhotosToCloud(imagesToSave);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div>
      <DialogHeader className="mb-4">
        <DialogTitle>Crop photo</DialogTitle>
        <DialogDescription>Position your photo within the crop area</DialogDescription>
      </DialogHeader>
      <div className="relative w-full h-[340px]">
        <Cropper
          image={photoFile.preview}
          crop={crop}
          cropSize={{ width: TR_PHOTO_USER_PREVIEW_WIDTH, height: TR_PHOTO_USER_PREVIEW_HEIGHT }}
          zoom={zoom}
          aspect={TRANSIENT_RESERVATIONS_PHOTO_ASPECT_RATIO}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </div>
      <div className="grid gap-3 py-4 px-6">
        <Label>Zoom</Label>
        <Slider
          disabled={isUploading}
          value={[zoom]}
          min={1}
          max={3}
          step={0.01}
          onValueChange={(val) => setZoom(val[0])}
          className="w-full hover:cursor-pointer"
        />
      </div>
      <DialogFooter className="mx-6 mt-4">
        <Button onClick={onCropClicked} disabled={isUploading}>
          Crop & Upload
        </Button>
      </DialogFooter>
    </div>
  );
}
