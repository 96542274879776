import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from 'components/ui/button';
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form';
import { Input } from 'components/ui/input';
import { Separator } from 'components/ui/separator';
import { Textarea } from 'components/ui/textarea';
import { useForm } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import { z } from 'zod';

const gateSettingsFormSchema = z.object({
  maxRetries: z.preprocess(
    (x) => {
      if (x === '') {
        return undefined;
      }
      if (x !== null && x !== undefined) {
        return String(x).replace(/[\$,%]/g, '');
      }
      return x;
    },
    z.coerce.number({
      required_error: 'Please input a number',
      invalid_type_error: 'Please input a number'
    })
  ),
  retryInterval: z.preprocess(
    (x) => {
      if (x === '') {
        return undefined;
      }
      if (x !== null && x !== undefined) {
        return String(x).replace(/[\$,%]/g, '');
      }
      return x;
    },
    z.coerce.number({
      required_error: 'Please input a number',
      invalid_type_error: 'Please input a number'
    })
  ),
  firstWarningText: z.string().max(500).optional(),
  finalFailureText: z.string().max(500).optional()
});

export default function GateSettingsTab({ defaultValues, onSubmit, disabled, canEdit }) {
  const form = useForm({
    resolver: zodResolver(gateSettingsFormSchema),
    defaultValues,
    mode: 'onChange'
  });

  const onSubmitForm = async (data) => {
    const firstWarningText = data.firstWarningText === '' ? null : data.firstWarningText ?? null;
    const finalFailureText = data.finalFailureText === '' ? null : data.finalFailureText ?? null;

    onSubmit({ ...data, firstWarningText, finalFailureText });
  };

  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium">Gate Settings</h3>
        <p className="text-sm text-muted-foreground">Update your settings for the gate hardware at your location.</p>
      </div>
      <Separator />

      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmitForm)}>
          <div className="max-w-2xl text-left space-y-3 sm:space-y-8 mb-4">
            <FormField
              control={form.control}
              name="maxRetries"
              render={({ field }) => {
                const { ref, ...rest } = field;
                return (
                  <FormItem>
                    <div className="space-y-2 text-left justify-start">
                      <FormLabel>Max gate open retries</FormLabel>

                      <FormControl>
                        <NumericFormat className="max-w-20" {...rest} customInput={Input} decimalScale={0} allowNegative={false} />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                );
              }}
            />
            <FormField
              control={form.control}
              name="retryInterval"
              render={({ field }) => {
                const { ref, ...rest } = field;
                return (
                  <FormItem>
                    <div className="space-y-2 text-left justify-start">
                      <FormLabel>Gate open retry interval (milliseconds)</FormLabel>

                      <FormControl>
                        <NumericFormat className="max-w-20" {...rest} customInput={Input} decimalScale={0} allowNegative={false} />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                );
              }}
            />
            <FormField
              control={form.control}
              name="firstWarningText"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Gate open failure warning text</FormLabel>
                  <FormControl>
                    <Textarea
                      placeholder="Write the message you would like the parker to see"
                      className="resize-none min-h-[80px]"
                      {...field}
                      disabled={!canEdit}
                    />
                  </FormControl>
                  <FormDescription>
                    This will be sent to the parker if the gate doesn't open on first attempt. We'll retry but the parker may want to be warned. Leave
                    this empty to not send text
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="finalFailureText"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Final gate open failure text</FormLabel>
                  <FormControl>
                    <Textarea
                      placeholder="Write the message you would like the parker to see"
                      className="resize-none min-h-[80px]"
                      {...field}
                      disabled={!canEdit}
                    />
                  </FormControl>
                  <FormDescription>
                    This will be sent to the parker after all retries have been attempted and we're unable to open the gate. Leave this empty to not
                    send text
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          {canEdit && (
            <Button type="submit" className="mt-4" disabled={disabled}>
              Update
            </Button>
          )}
        </form>
      </Form>
    </div>
  );
}
