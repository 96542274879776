import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Calendar, Views, dayjsLocalizer } from 'react-big-calendar-multiday-drag';
import withDragAndDrop from 'react-big-calendar-multiday-drag/lib/addons/dragAndDrop';
import { Prompt } from 'react-router';

import './Addons.css';
import EditRateTypeDialog from './EditRateType';
import NewRateTypeDialog from './NewRateTypeDialog';
import './RateCalendar.css';

import { Button } from 'components/ui/button';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from 'components/ui/card';
import { Skeleton } from 'components/ui/skeleton';
import { Skeleton as SkeletonNoPulse } from 'components/ui/skeleton-nopulse';
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip';
import { useToast } from 'components/ui/use-toast';
import { ClipboardPaste, Copy, Loader2 } from 'lucide-react';
import { AppContext } from '../../context/provider';
import {
  deleteRateScheduleAPI,
  getEFRsAPI,
  getLocationPriceDescriptorsAPI,
  getLocationRateResetTimesAPI,
  getRateSchedulesAPI,
  updateEFRsAPI,
  updateRateResetTimeAPI,
  updateRateStructurePriceDescriptorsAPI,
  upsertRateScheduleAPI
} from '../../services/RateSchedule';
import ChangeDayStartDialog from './ChangeDayStartDialog';
import ConfirmDeleteScheduleDialog from './ConfirmDeleteScheduleDialog';
import EditEFRDialog from './EditEFRDialog';
import EditGracePeriodDialog from './EditGracePeriodDialog';
import SelectLocationDropdown from './SelectLocationDropdown';
import SelectScheduleDropdown from './SelectScheduleDropdown';
import { calendarErrorEventColor, materialColorPallet } from './colors';
import {
  convertCalendarEventsToRateStructure,
  convertDatabaseEFRToDayjsEFR,
  convertDayjsEFRToDatabaseEFR,
  convertOffsetToTimeString,
  convertRateStructureToCalendarEvents,
  convertSequilizeDayStartTimeToOffset,
  defaultCalendarStartDate,
  displayIntervalMinsForTitle,
  displayMinsAsHoursAndMinsShort
} from './helpers';
import SetPriceDescriptorsDialog, { defaultInitialPriceDescriptors } from './SetPriceDescriptorsDialog';
import { getLocationDetailsAPI } from '../../services/ManageLocations';

const dayjs = require('dayjs');
const uuid = require('uuid');
const en = require('dayjs/locale/en');

const localizer = dayjsLocalizer(dayjs);
const RateSelectionCalendar = withDragAndDrop(Calendar);

const RED_GAPS_DISPLAY_TIME_MS = 1000; // How long to highlight gaps when user clicks submit with gaps in schedule
const UNSAVED_CHANGES_MESSAGE = 'You have unsaved changes to your schedule, are you sure you want to leave?';
const NEW_SCHEDULE_ID = 'NEW_SCHEDULE';
const NEW_DEFAULT_SCHEDULE_ID = 'NEW_DEFAULT_SCHEDULE_ID';
const NEW_DEFAULT_SCHEDULE_NAME = 'Default'; // The name for a new default schedule. Due to current DB setup, should always be 'Default' to avoid confusion

const isEventDraggable = (event /* eslint-disable-line no-unused-vars */) => false;

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
};

export default function RateCalendar() {
  const [events, setEvents] = useState([]);
  const [backgroundEvents, setBackgroundEvents] = useState([]);
  const [windowDimensions] = useState(getWindowDimensions());

  const [newEventTimeSlot, setNewEventTimeSlot] = useState(null);
  const [editEvent, setEditEvent] = useState(null);
  const [isDayStartDialogOpen, setIsDayStartDialogOpen] = useState(false);
  const [savingSchedule, setSavingSchedule] = useState(false);
  const [deletingSchedule, setDeletingSchedule] = useState(false);
  const [hoveredEventID, setHoveredEventID] = useState(null);
  const [dayStartOffset, setDayStartOffset] = useState(0);
  const [currentWeekStart, setCurrentWeekStart] = useState(0);
  const [selectedLocation, setSelectedLocation] = useState(undefined);
  const [selectedScheduleId, setSelectedScheduleId] = useState(undefined);
  const [loadingLocation, setLoadingLocation] = useState(undefined);
  const [isEFRDialogOpen, setIsEFRDialogOpen] = useState(false);
  const [isGracePeriodDialogOpen, setIsGracePeriodDialogOpen] = useState(false);
  const [locationEFRs, setLocationEFRs] = useState(null);
  const [gracePeriod, setGracePeriod] = useState(undefined);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [locationSchedules, setLocationSchedules] = useState([]);
  const [locationRateResetData, setLocationRateResetData] = useState([]);
  const [locationPriceDescriptorData, setLocationPriceDescriptorData] = useState([]);
  const [newScheduleName, setNewScheduleName] = useState(null);
  const [copiedDay, setCopiedDay] = useState(null);
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState(false);
  const [isSetPriceDescriptorsOpen, setIsSetPriceDescriptorsOpen] = useState(false);
  const [priceDescriptors, setPriceDescriptors] = useState([]);
  const [pendingEFRChanges, setPendingEFRChanges] = useState({ added: [], deleted: [] });
  const [locationDetails, setLocationDetails] = useState(null);

  const { toast } = useToast();
  const { permissions } = useContext(AppContext);
  const selectableLocations = useMemo(
    () =>
      permissions.rateSettingsLocations
        .filter((lot) => permissions.rateSettingsPermissions[lot.location_id]?.canEdit ?? false)
        .sort((a, b) => a.location_name.localeCompare(b.location_name)),
    [permissions]
  );

  const selectableSchedules = useMemo(() => {
    // Sort schedules so default is always displayed at the top
    const defaultSchedule = locationSchedules.find((schedule) => schedule.isDefault === true);

    // No default schedule when the location has been newly created. We want to force the user to create a default schedule.
    let sortedSchedules;
    if (defaultSchedule) {
      sortedSchedules = [defaultSchedule, ...locationSchedules.filter((schedule) => schedule !== defaultSchedule)];
    } else {
      sortedSchedules = [{ id: NEW_DEFAULT_SCHEDULE_ID, name: NEW_DEFAULT_SCHEDULE_NAME }, ...locationSchedules];
    }

    const schedules = sortedSchedules.map((el) => ({ id: el.id, name: el.name }));
    if (newScheduleName) {
      schedules.push({ id: NEW_SCHEDULE_ID, name: newScheduleName });
    }
    return schedules;
  }, [locationSchedules, newScheduleName]);

  useEffect(() => {
    // Setup and cleanup of the dayjs locale (used for calender week start day)
    const resetDayJSLocal = () => {
      dayjs().locale(en).format();
    };

    dayjs().locale(en).format();
    return resetDayJSLocal;
  }, []);

  useEffect(() => {
    // Prevent the browser from navigating away immediately when user has unsaved changes
    const handleBeforeUnload = (event) => {
      if (unsavedChanges) {
        event.preventDefault();
        event.returnValue = '';
        return UNSAVED_CHANGES_MESSAGE;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [unsavedChanges]);

  const titleAccessor = useMemo(
    () =>
      function (event) {
        // If the event is too short there isn't enough space to display the text nicely
        if (event.duration < 60) {
          return <div></div>;
        }
        const isHovered = hoveredEventID === event?.id;
        if (isHovered)
          return (
            <div className="mt-1 h-full flex items-center justify-center">
              <p className="text-lg font-bold">Click to edit...</p>
            </div>
          );

        switch (event?.type) {
          case 'flat':
            return (
              <div className="mt-1 h-full flex items-center justify-center">
                <p className="text-md">
                  <span className="font-bold text-lg">${event.data.rate}</span> flat rate
                </p>
              </div>
            );
          case 'interval':
            return (
              <div className="mt-1 h-full flex items-center justify-center">
                <div>
                  <p className="text-md">
                    <span className="font-bold text-lg">${event.data.rate}</span> every {displayIntervalMinsForTitle(event.data.interval)}
                  </p>
                  {event.data.dailyMax && (
                    <p className="text-md">
                      Daily max <span className="font-bold text-lg">${event.data.dailyMax}</span>
                    </p>
                  )}
                </div>
              </div>
            );
          case 'variable':
            return null;
          case 'background':
            return (
              <div className="mb-1 mt-1 h-full flex items-end justify-center ">
                <p className="text-md truncate">
                  {event.data.titleData.upTo} - <span className="font-bold text-lg">${event.data.titleData.cumulativeRate}</span>
                </p>
              </div>
            );
          default:
            return null;
        }
      },
    [hoveredEventID]
  );

  const eventStyleGetter = useMemo(
    // Disabling linting for eventStyleGetter function definition so the callback params are more obvious to anyone editing this code in the future
    // eslint-disable-next-line no-unused-vars
    () => (event, start, end, isSelected) => {
      const isHighlighted = hoveredEventID === event?.id;
      const eventBorderRadius = '10px';
      let style = {
        opacity: 0.9,
        borderRadius: eventBorderRadius,
        borderWidth: '1px',
        borderColor: materialColorPallet.schemes.light.surface,
        color: materialColorPallet.schemes.light.onPrimary,
        display: 'block',
        cursor: 'pointer'
      };
      const colShades = ['25', '40'];
      const eventIndex = event.type === 'background' ? event.data.parentIndex : event.index;
      const backColor = materialColorPallet.palettes.primary[colShades[eventIndex % colShades.length]];
      switch (event?.type) {
        case 'flat':
          style = { ...style, backgroundColor: backColor };
          break;
        case 'interval':
          style = { ...style, backgroundColor: backColor };
          break;
        case 'variable':
          style = {
            ...style,
            backgroundColor: 'rgba(0, 0, 0, 0.0)'
          };
          break;
        case 'background':
          style = {
            ...style,
            backgroundColor: backColor,
            borderBottom: event.data.isLast ? 'none' : '2px solid',
            borderBottomColor: materialColorPallet.palettes.primary['60'],
            borderRadius: '0px',
            borderWidth: '0px',
            borderTopLeftRadius: event.data.isFirst ? eventBorderRadius : '0px',
            borderTopRightRadius: event.data.isFirst ? eventBorderRadius : '0px',
            borderBottomLeftRadius: event.data.isLast ? eventBorderRadius : '0px',
            borderBottomRightRadius: event.data.isLast ? eventBorderRadius : '0px'
          };
          break;
        case 'error':
          style = {
            ...style,
            backgroundColor: calendarErrorEventColor,
            border: '0px',
            borderRadius: '5px'
          };
          break;
        default:
          break;
      }

      // Styles for hover and selected states
      if (isHighlighted && event?.type !== 'background' && event?.type !== 'error') {
        style = {
          ...style,
          backgroundColor: materialColorPallet.schemes.light.primaryFixedDim,
          color: materialColorPallet.schemes.light.onPrimaryFixed,
          borderWidth: '2px',
          borderColor: materialColorPallet.schemes.light.outline
        };
      }

      return { style };
    },
    [hoveredEventID]
  );

  const handleMouseEnterEvent = (event) => {
    if (event.type === 'background' || event.type === 'error') return;
    setHoveredEventID(event.id);
  };

  const handleMouseLeaveEvent = (event) => {
    if (event.type === 'background' || event.type === 'error') return;
    setHoveredEventID(null);
  };

  const setCalendarState = (locationRateResetData, priceDescriptorData, locationSchedules, selectedScheduleId) => {
    if (selectedScheduleId === NEW_DEFAULT_SCHEDULE_ID) {
      setPriceDescriptors(defaultInitialPriceDescriptors);
      setGracePeriod(null);
      setCurrentWeekStart(0);
      setDayStartOffset(0);
      setEvents([]);
      setUnsavedChanges(true);
      return;
    }

    const rateResetData = locationRateResetData.find((el) => el.rateStructureId === selectedScheduleId);
    if (!rateResetData) throw new Error('Cant find rate reset time for schedule');
    const rateSchedule = locationSchedules.find((el) => el.id === selectedScheduleId);
    if (!rateSchedule) throw new Error('Cant find schedule');
    const schedulePriceDescriptors = priceDescriptorData[selectedScheduleId]?.length
      ? [...priceDescriptorData[selectedScheduleId], { descriptor: '', amount: '', id: uuid.v4() }]
      : defaultInitialPriceDescriptors;

    const locationDayStartOffset = convertSequilizeDayStartTimeToOffset(rateResetData.resetTime);
    const newEvents = convertRateStructureToCalendarEvents(rateSchedule, locationDayStartOffset, rateResetData.resetDay);
    setPriceDescriptors(schedulePriceDescriptors);
    setGracePeriod(rateSchedule.gracePeriod);
    setCurrentWeekStart(rateResetData.resetDay);
    setDayStartOffset(locationDayStartOffset);
    setEvents(newEvents);
  };

  const onSelectSchedule = (scheduleId) => {
    if (unsavedChanges) {
      // eslint-disable-next-line
      const result = window.confirm(UNSAVED_CHANGES_MESSAGE);
      if (!result) {
        return;
      }
    }
    setUnsavedChanges(false);
    setSelectedScheduleId(scheduleId);
    setNewScheduleName(null);

    try {
      setCalendarState(locationRateResetData, locationPriceDescriptorData, locationSchedules, scheduleId);
    } catch (error) {
      console.error('Error:', error);
      setSelectedScheduleId(undefined);
      toast({
        title: 'Uh oh! Something went wrong.',
        description: 'We were unable to load your schedule. Please let us know!'
      });
    }
  };

  const onSelectLocation = (locationId, defaultScheduleId = null, force = false) => {
    if (unsavedChanges && !force) {
      // eslint-disable-next-line
      const result = window.confirm(UNSAVED_CHANGES_MESSAGE);
      if (!result) {
        return;
      }
    }
    setLocationEFRs(null);
    setPendingEFRChanges({ added: [], deleted: [] });
    setNewScheduleName(null);
    setPriceDescriptors(defaultInitialPriceDescriptors);
    setGracePeriod(undefined);
    setUnsavedChanges(false);
    setSelectedLocation(locationId);
    setSelectedScheduleId(undefined);
    setLoadingLocation(true);
    const fetchData = async () => {
      try {
        const [
          { success: rsSuccess, data: rateSchedules },
          { success: efrSuccess, data: locationsEfrs },
          { success: rrtSuccess, data: rateResetData },
          { success: pdSuccess, data: priceDescriptorData },
          { success: ldSuccess, data: locationDetailsData }
        ] = await Promise.all([
          getRateSchedulesAPI({ locationId }),
          getEFRsAPI({ locationId }),
          getLocationRateResetTimesAPI({ locationId }),
          getLocationPriceDescriptorsAPI({ locationId }),
          getLocationDetailsAPI({ locationId })
        ]);

        if (!rsSuccess || !rateSchedules) throw new Error('No schedules found at location');
        if (!efrSuccess) throw new Error('Cant fetch EFRs for location');
        if (!rrtSuccess) throw new Error('Cant fetch rate reset time for location');
        if (!pdSuccess) throw new Error('Cant fetch price descriptors for location');
        if (!ldSuccess) throw new Error('Cant fetch location details');

        setLocationSchedules(rateSchedules);
        setLocationRateResetData(rateResetData);
        setLocationPriceDescriptorData(priceDescriptorData);
        setLocationDetails(locationDetailsData);

        // Automatically select default when new location selected (ignore force reload)
        const defaultId = defaultScheduleId ? defaultScheduleId : rateSchedules.find((rs) => rs.isDefault)?.id ?? NEW_DEFAULT_SCHEDULE_ID;
        setSelectedScheduleId(defaultId);
        setCalendarState(rateResetData, priceDescriptorData, rateSchedules, defaultId);

        setLocationEFRs(locationsEfrs.map((efr) => convertDatabaseEFRToDayjsEFR(efr)));
        setLoadingLocation(false);
      } catch (error) {
        console.error('Error:', error);
        setLoadingLocation(false);
        setSelectedLocation(undefined);
        toast({
          title: 'Uh oh! Something went wrong.',
          description: 'We were unable to load your schedules for this lot. Please let us know!'
        });
      }
    };

    fetchData();
  };

  useEffect(() => {
    dayjs()
      .locale({
        ...en,
        weekStart: currentWeekStart
      })
      .format();

    // Reseting events forces calendar to refresh display after day start is changed
    setEvents((prevEv) => prevEv.map((ev) => ({ ...ev })));
  }, [currentWeekStart]);

  const tooltipAccessor = (event) => {
    let startDate = dayjs(event.start).add(dayStartOffset, 'minutes');
    let endDate = dayjs(event.end).add(dayStartOffset, 'minutes');

    // Round the display to make sure events that end at 11:59:59 are displayed like 12:00
    startDate = startDate.add(1, 'second').startOf('minute');
    endDate = endDate.add(1, 'second').startOf('minute');

    let formattedDate;
    if (startDate.isSame(endDate, 'day')) {
      // Same day
      formattedDate = `${startDate.format('dddd h:mma')} - ${endDate.format('h:mma')}`;
    } else {
      // Different days
      formattedDate = `${startDate.format('dddd h:mma')} - ${endDate.format('dddd h:mma')}`;
    }
    return formattedDate;
  };

  const onDrillDown = (dateClicked) => {
    const clickedDay = dayjs(dateClicked);
    if (copiedDay === null) {
      // Attempt to copy day
      const dayContainsMultidayEvent = events.some(
        (event) =>
          dayjs(event.start).isSame(clickedDay, 'day') !== dayjs(event.end).isSame(clickedDay, 'day') ||
          (dayjs(event.start).isBefore(clickedDay.startOf('day')) && dayjs(event.end).isAfter(clickedDay.endOf('day')))
      );
      if (dayContainsMultidayEvent) {
        toast({
          title: "Can't copy day.",
          description: 'You cannot copy a day which contains multiday events.'
        });
        return;
      }
      setCopiedDay(clickedDay);
      return;
    }

    const hasEventsOnPasteDay = events.some(
      (event) =>
        dayjs(event.start).isSame(clickedDay, 'day') ||
        dayjs(event.end).isSame(clickedDay, 'day') ||
        (dayjs(event.start).isBefore(clickedDay.startOf('day')) && dayjs(event.end).isAfter(clickedDay.endOf('day')))
    );
    if (hasEventsOnPasteDay) {
      toast({
        title: "Can't paste events.",
        description: 'You cannot paste over a day which is not empty.'
      });
      setCopiedDay(null);
      return;
    }

    !unsavedChanges && setUnsavedChanges(true);
    const daysDiff = clickedDay.diff(copiedDay, 'day');
    setEvents((prevEvents) => {
      const copiedEvents = prevEvents
        .filter((event) => dayjs(event.start).isSame(copiedDay, 'day') && dayjs(event.end).isSame(copiedDay, 'day'))
        .map((event, idx) => ({
          ...event,
          start: dayjs(event.start).add(daysDiff, 'day').toDate(),
          end: dayjs(event.end).add(daysDiff, 'day').toDate(),
          id: uuid.v4(),
          index: prevEvents.length + idx
        }));

      return [...prevEvents, ...copiedEvents];
    });
    setCopiedDay(null);
  };

  const { defaultDate, formats } = useMemo(() => {
    const formatShortDisplayTime = (date) => {
      let displayDate = date;
      const originalDay = displayDate.day();

      // Round the display to make sure events that end at 11:59:59 are displayed like 12:00
      displayDate = displayDate.add(1, 'second').startOf('minute');

      // Add the day start offset (for if user sets the calendar to start at a custom hour)
      displayDate = displayDate.add(dayStartOffset, 'minutes');
      const offSetAdjustedDay = displayDate.day();

      let dateFormatted;
      if (displayDate.minute() === 0) {
        dateFormatted = originalDay === offSetAdjustedDay ? displayDate.format('ha') : displayDate.format('ha (ddd)');
      } else {
        dateFormatted = originalDay === offSetAdjustedDay ? displayDate.format('h:mma') : displayDate.format('h:mma (ddd)');
      }
      return dateFormatted;
    };

    const formatTimeRange = (range) => {
      const displayStart = dayjs(range.start);
      const displayEnd = dayjs(range.end);
      const startTimeFormatted = formatShortDisplayTime(displayStart);
      const endTimeFormatted = formatShortDisplayTime(displayEnd);

      return `${startTimeFormatted} - ${endTimeFormatted}`;
    };

    return {
      defaultDate: dayjs(defaultCalendarStartDate).add(currentWeekStart, 'day').toDate(),
      formats: {
        dayFormat: (date, culture, localizer) => localizer.format(date, 'dddd', culture),
        timeGutterFormat: (date) => dayjs(date).add(dayStartOffset, 'minutes').format('h:mm A'),
        selectRangeFormat: formatTimeRange,
        eventTimeRangeFormat: () => null,
        eventTimeRangeEndFormat: () => null,
        eventTimeRangeStartFormat: () => null
      }
    };
  }, [dayStartOffset, currentWeekStart]);

  // Make sure the user cant select overlapping events
  const handleOnSelecting = useCallback(
    (event) => {
      const eventStart = dayjs(event.start);
      let isOverlapping = false;

      for (const existingEvent of events) {
        const existingStart = dayjs(existingEvent.start);
        const existingEnd = dayjs(existingEvent.end);

        if (eventStart.isBetween(existingStart, existingEnd)) {
          isOverlapping = true;
        }
      }

      return !isOverlapping;
    },
    [events]
  );

  const handleSelectSlot = useCallback(
    ({ start, end }) => {
      const newStart = dayjs(start);
      const newEnd = dayjs(end);

      for (const existingEvent of events) {
        const existingStart = dayjs(existingEvent.start);
        const existingEnd = dayjs(existingEvent.end);
        if (
          newStart.isBetween(existingStart, existingEnd, null, '()') ||
          newEnd.isBetween(existingStart, existingEnd, null, '()') ||
          existingStart.isBetween(newStart, newEnd, null, '()') ||
          existingEnd.isBetween(newStart, newEnd, null, '()')
        ) {
          toast({
            title: 'No overlapping events.',
            description: 'You cannot have overlapping rates.'
          });
          return;
        }
      }

      setNewEventTimeSlot({ start, end });
    },
    [setNewEventTimeSlot, events]
  );

  useEffect(() => {
    const newBackgroundEvents = [];
    const variableEvents = events.filter((ev) => ev.type === 'variable');
    for (const event of variableEvents) {
      let currentDay = dayjs(event.start);
      while (currentDay.isBefore(dayjs(event.end))) {
        const endOfCurrentDay = currentDay.isSame(dayjs(event.end), 'day')
          ? dayjs(event.end)
          : currentDay.add(1, 'day').startOf('day').subtract(1, 'second');
        let backgroundNum = 0;
        let cumulativeRate = 0;
        for (const slot of event.data.rates) {
          const start = currentDay.add(slot.start, 'minute').toDate();
          const slotEndtime = currentDay.add(slot.end, 'minute');
          const slotTimeEndsBeforeEOD = slotEndtime.isBefore(endOfCurrentDay);
          const end = slotTimeEndsBeforeEOD ? slotEndtime.toDate() : endOfCurrentDay.toDate();
          cumulativeRate += slot.rate;

          const titleData = {
            upTo: displayMinsAsHoursAndMinsShort(slot.end),
            over: displayMinsAsHoursAndMinsShort(slot.start),
            cumulativeRate
          };

          newBackgroundEvents.push({
            start,
            end,
            title: '',
            id: uuid.v4(),
            type: 'background',
            duration: dayjs(end).diff(dayjs(start), 'minute'),
            data: {
              parentId: event.id,
              parentIndex: event.index,
              backgroundNum,
              titleData,
              isFirst: !slot.start,
              isLast: !slotTimeEndsBeforeEOD
            }
          });

          if (!slotTimeEndsBeforeEOD) {
            break;
          }
          backgroundNum += 1;
        }

        currentDay = currentDay.add(1, 'day').startOf('day');
      }
    }

    setBackgroundEvents(newBackgroundEvents);
  }, [events, setBackgroundEvents]);

  const addRateEvent = useCallback(
    ({ title, start, end, type, data = null }) => {
      const newEvent = { start, end, title, id: uuid.v4(), type, data, duration: dayjs(end).diff(dayjs(start), 'minute') };
      !unsavedChanges && setUnsavedChanges(true);
      // The sorting and index mapping is just to make sure the events display correctly with alternating colors
      setEvents((prev) => [...prev, newEvent].sort((a, b) => dayjs(a.start).diff(dayjs(b.start))).map((el, idx) => ({ ...el, index: idx })));
      setNewEventTimeSlot(null);
    },
    [setEvents, setNewEventTimeSlot, setUnsavedChanges, unsavedChanges]
  );

  const editRateEvent = useCallback(
    ({ oldEvent, newStart, newEnd, newTitle, newData }) => {
      !unsavedChanges && setUnsavedChanges(true);
      setEvents((prevEvents) =>
        prevEvents.map((event) =>
          event.id === oldEvent.id
            ? { ...event, start: newStart, end: newEnd, title: newTitle, duration: dayjs(newEnd).diff(dayjs(newStart), 'minute'), data: newData }
            : event
        )
      );

      setEditEvent(null);
    },
    [setEvents, setEditEvent, setUnsavedChanges, unsavedChanges]
  );

  const handleSelectEvent = useCallback(
    (event) => {
      setEditEvent(event);
      setHoveredEventID(null);
    },
    [setEditEvent]
  );

  const handleDeleteEvent = useCallback(
    (eventId) => {
      !unsavedChanges && setUnsavedChanges(true);
      setEvents((prevEvents) => prevEvents.filter((e) => e.id !== eventId));
      setEditEvent(null);
    },
    [setEvents, setEditEvent, setUnsavedChanges, unsavedChanges]
  );

  const changeDayStartOffset = useCallback(
    (offset, weekStart) => {
      !unsavedChanges && setUnsavedChanges(true);
      setEvents([]);
      setDayStartOffset(offset);
      setCurrentWeekStart(weekStart);
      setIsDayStartDialogOpen(false);
    },
    [setDayStartOffset, setIsDayStartDialogOpen, setEvents, setCurrentWeekStart, setUnsavedChanges, unsavedChanges]
  );

  const changeGracePeriod = useCallback(
    (gracePeriod) => {
      !unsavedChanges && setUnsavedChanges(true);
      setGracePeriod(gracePeriod);
      setIsGracePeriodDialogOpen(false);
    },
    [setGracePeriod, setIsGracePeriodDialogOpen, setUnsavedChanges, unsavedChanges]
  );

  const onAddNewSchedule = (name) => {
    !unsavedChanges && setUnsavedChanges(true);
    setNewScheduleName(name);

    setSelectedScheduleId(NEW_SCHEDULE_ID);
    setPriceDescriptors(defaultInitialPriceDescriptors);
    setGracePeriod(null);
    setCurrentWeekStart(0);
    setDayStartOffset(0);
    setEvents([]);
  };

  const validateSchedule = () => {
    const startOfWeek = dayjs(defaultDate);
    const endOfWeek = startOfWeek.endOf('week');
    const cleanedEvents = [];
    let gapEvents = [];
    let stepTime = startOfWeek;
    while (endOfWeek.isAfter(stepTime)) {
      const cStepTime = stepTime;
      const curEvent = events.find((e) => dayjs(e.start).isSame(cStepTime));
      if (curEvent) {
        stepTime = dayjs(curEvent.end);
        cleanedEvents.push(curEvent);
      } else {
        // We've got a gap in the schedule
        const nextEvent = events.reduce((prev, cur) => {
          if (dayjs(cur.start).isAfter(cStepTime) && (!prev || dayjs(cur.start).isBefore(prev.start))) {
            return cur;
          }
          return prev;
        }, null);

        if (nextEvent) {
          gapEvents.push({
            start: stepTime.toDate(),
            end: nextEvent.start,
            title: '',
            id: uuid.v4(),
            type: 'error'
          });
          stepTime = dayjs(nextEvent.start);
        } else {
          gapEvents.push({
            start: stepTime.toDate(),
            end: endOfWeek.toDate(),
            title: '',
            id: uuid.v4(),
            type: 'error'
          });
          stepTime = endOfWeek;
        }
      }
    }
    gapEvents = gapEvents
      .map((e) => {
        const eventEnd = dayjs(e.end);
        if (eventEnd.format('HH:mm:ss') === '00:00:00') {
          return { ...e, end: eventEnd.subtract(1, 'seconds').toDate() };
        }
        return e;
      })
      .filter((e) => dayjs(e.end).diff(dayjs(e.start), 'seconds') > 0);

    if (gapEvents.length) {
      setBackgroundEvents((old) => [...old, ...gapEvents]);
      toast({
        title: 'Fill in the yellow gaps.',
        description: 'Please define rates for the entire weekly schedule. There can be no gaps.'
      });

      // Clear errors after a few seconds
      setTimeout(() => {
        setBackgroundEvents((old) => old.filter((e) => e.type !== 'error'));
      }, RED_GAPS_DISPLAY_TIME_MS);
      return false;
    }
    return true;
  };

  const handleSubmit = async (cleanedPriceDescriptors) => {
    if (!selectedLocation || !selectedScheduleId) {
      // Should never happen
      toast({
        title: 'No location and schedule selected.',
        description: 'Please select a location and schedule.'
      });
    }

    const isScheduleValid = validateSchedule();
    if (!isScheduleValid) {
      return;
    }

    // No gaps, we're good to submit the users schedule!
    try {
      toast({
        title: 'Saving Schedule...',
        description: 'Please wait while we save your new schedule'
      });
      const rateStructure = convertCalendarEventsToRateStructure(events, dayStartOffset);
      const rateStructureName = selectableSchedules.find((el) => el.id === selectedScheduleId).name;
      // setting rateStructureId to null will add a new schedule
      const rateStructureId = selectedScheduleId === NEW_SCHEDULE_ID || selectedScheduleId === NEW_DEFAULT_SCHEDULE_ID ? null : selectedScheduleId;
      const forceIsDefault = selectedScheduleId === NEW_DEFAULT_SCHEDULE_ID ? true : false;
      setSavingSchedule(true);
      const { success, data: newRateSchedule } = await upsertRateScheduleAPI({
        payload: {
          locationId: selectedLocation,
          rateStructureId,
          rateStructureName,
          rateStructure,
          gracePeriod: gracePeriod ? gracePeriod : null,
          forceIsDefault
        }
      });
      if (!success || !newRateSchedule?.id) {
        throw new Error('Error upserting rate schedule');
      }

      // Update the day start time and price descriptors
      const [dsResp, pdResp, efrsResp] = await Promise.all([
        updateRateResetTimeAPI({
          payload: {
            locationId: selectedLocation,
            rateStructureId: newRateSchedule.id,
            resetTime: convertOffsetToTimeString(dayStartOffset),
            resetDay: currentWeekStart
          }
        }),
        updateRateStructurePriceDescriptorsAPI({
          payload: {
            locationId: selectedLocation,
            rateStructureId: newRateSchedule.id,
            descriptors: cleanedPriceDescriptors
          }
        }),
        updateEFRsAPI({
          payload: {
            locationId: selectedLocation,
            pendingChanges: {
              added: pendingEFRChanges.added.map((efr) => convertDayjsEFRToDatabaseEFR(efr)),
              deleted: pendingEFRChanges.deleted
            }
          }
        })
      ]);
      if (!dsResp.success) {
        throw new Error(dsResp.message);
      }
      if (!pdResp.success) {
        throw new Error(pdResp.message);
      }
      if (!efrsResp.success) {
        throw new Error(efrsResp.message);
      }

      onSelectLocation(selectedLocation, newRateSchedule.id, true);
      setUnsavedChanges(false);
      setSavingSchedule(false);
      toast({
        title: 'Schedule Saved!',
        description: 'Your schedule has been successfully saved'
      });
    } catch (e) {
      setSavingSchedule(false);
      // should never reach here, unless there's a bug
      console.error('Error saving rate structure:', e.message);
      toast({
        title: 'Uh oh! Something went wrong.',
        description: 'There was an error saving your rate schedule. Please let us know!',
        variant: 'destructive'
      });
    }
  };

  const handleDeleteSchedule = async () => {
    const selectedSchedule = locationSchedules.find((el) => el.id === selectedScheduleId);
    if (selectedSchedule?.isDefault) {
      toast({
        title: 'Uh oh! Something went wrong.',
        description: 'Cannot delete your default schedule!',
        variant: 'destructive'
      });
      return;
    }

    try {
      toast({
        title: 'Deleting Schedule...',
        description: 'Please wait while we delete your schedule'
      });
      setDeletingSchedule(true);

      await deleteRateScheduleAPI({
        payload: {
          locationId: selectedLocation,
          rateStructureId: selectedScheduleId
        }
      });

      // Reload location schedules now that we've deleted one
      onSelectLocation(selectedLocation, null, true);
      setUnsavedChanges(false);
      setDeletingSchedule(false);
      toast({
        title: 'Schedule Deleted!',
        description: 'Your schedule has been successfully deleted'
      });
    } catch (e) {
      setDeletingSchedule(false);
      // should never reach here, unless there's a bug
      console.error('Error deleting rate structure:', e.message);
      toast({
        title: 'Uh oh! Something went wrong.',
        description: 'There was an error deleting your schedule. Please let us know!',
        variant: 'destructive'
      });
    }
  };

  const calendarComponents = useMemo(
    () => ({
      eventWrapper: ({ event, children }) => (
        <div onMouseEnter={() => handleMouseEnterEvent(event)} onMouseLeave={() => handleMouseLeaveEvent(event)}>
          {children}
        </div>
      ),
      week: {
        header: ({ date, localizer }) => {
          if (windowDimensions.width < 640) {
            return <div>{localizer.format(date, 'ddd')}</div>;
          }
          return (
            <Tooltip>
              <TooltipTrigger asChild>
                <div className="flex justify-between items-center">
                  {localizer.format(date, 'dddd')}
                  {copiedDay === null && <Copy className="h-4 w-4 ml-2" />}
                  {copiedDay !== null && !dayjs(date).isSame(copiedDay, 'day') && <ClipboardPaste className="h-4 w-4 ml-2" />}
                </div>
              </TooltipTrigger>
              {copiedDay === null && <TooltipContent>Copy Events</TooltipContent>}
              {copiedDay !== null && !dayjs(date).isSame(copiedDay, 'day') && <TooltipContent>Paste Events</TooltipContent>}
            </Tooltip>
          );
        }
      }
    }),
    [handleMouseEnterEvent, handleMouseLeaveEvent, copiedDay, windowDimensions]
  );

  return (
    <div>
      <Prompt when={unsavedChanges} message={UNSAVED_CHANGES_MESSAGE} />
      <Card>
        <CardHeader className="pb-0 mb-2 pt-1 md:pt-3">
          <CardTitle className="text-lg md:text-2xl">Configure Parking Rates</CardTitle>
          <CardDescription className="hidden md:block">Set your parking lot rates on a weekly schedule.</CardDescription>
          <div className="sm:flex flex-wrap justify-between gap-2">
            <div className="flex justify-between sm:space-x-6">
              <SelectLocationDropdown
                selectedLocation={selectedLocation}
                onSelectLocation={onSelectLocation}
                selectableLocations={selectableLocations}
              />
              <SelectScheduleDropdown
                selectedSchedule={selectedScheduleId}
                onSelectSchedule={onSelectSchedule}
                selectableSchedules={selectableSchedules}
                onAddNewSchedule={onAddNewSchedule}
                allowCreateNew={selectedScheduleId !== NEW_DEFAULT_SCHEDULE_ID} // Can't create new schedule if they haven't created default yet
                disabled={selectedLocation === undefined || loadingLocation}
                unsavedChanges={unsavedChanges}
              />
            </div>
            <div className="flex space-x-6 hidden sm:block">
              <Button
                variant="outline"
                onClick={() => {
                  setIsDayStartDialogOpen(true);
                }}
                disabled={!selectedLocation || loadingLocation}>
                Change Day & Week Start
              </Button>
              <Button
                variant="outline"
                onClick={() => {
                  setIsEFRDialogOpen(true);
                }}
                disabled={locationEFRs === null}>
                Edit Early Bird Rates
              </Button>
              {/* <Button
                variant="outline"
                onClick={() => {
                  setIsGracePeriodDialogOpen(true);
                }}
                disabled={gracePeriod === undefined}>
                Edit Grace Period
              </Button> */}
            </div>
          </div>
        </CardHeader>
        <CardContent className="max-sm:pr-1 max-sm:pl-1">
          <div className="relative">
            {selectedLocation === undefined && (
              <SkeletonNoPulse className="absolute top-0 left-0 w-full h-full bg-white opacity-75 z-10 flex justify-center items-center">
                <p className="text-2xl text-muted">Select a location...</p>
              </SkeletonNoPulse>
            )}
            {selectedLocation !== undefined && selectedScheduleId === undefined && !loadingLocation && (
              <SkeletonNoPulse className="absolute top-0 left-0 w-full h-full bg-white opacity-75 z-10 flex justify-center items-center">
                <p className="text-2xl text-muted">Select a rate...</p>
              </SkeletonNoPulse>
            )}
            {loadingLocation && (
              <Skeleton className="absolute top-0 left-0 w-full h-full bg-white opacity-75 z-10 flex justify-center items-center">
                <p className="text-2xl text-muted">Loading...</p>
              </Skeleton>
            )}
            <RateSelectionCalendar
              defaultDate={defaultDate}
              date={defaultDate}
              onNavigate={() => {}} // Just to stop warning
              backgroundEvents={backgroundEvents}
              onDrillDown={onDrillDown}
              formats={formats}
              defaultView={Views.WEEK}
              toolbar={false}
              events={events}
              localizer={localizer}
              onSelectEvent={handleSelectEvent}
              onSelectSlot={handleSelectSlot}
              onSelecting={handleOnSelecting}
              showMultiDayTimes
              selectable
              popup
              resizable
              eventPropGetter={eventStyleGetter}
              // Calendar has display issues on anything less than 800px, so set a minimum height
              style={{ height: Math.max(700, windowDimensions.width < 640 ? windowDimensions.height - 200 : windowDimensions.height - 300) }}
              draggableAccessor={isEventDraggable}
              tooltipAccessor={tooltipAccessor}
              titleAccessor={titleAccessor}
              components={calendarComponents}
            />
          </div>
        </CardContent>
        <CardFooter className="max-sm:hidden flex justify-between mx-4">
          <div>
            {!deletingSchedule &&
              !locationSchedules.find((el) => el.id === selectedScheduleId)?.isDefault &&
              selectedScheduleId !== undefined &&
              selectedScheduleId !== NEW_DEFAULT_SCHEDULE_ID && (
                <Button variant="destructive" onClick={() => setIsConfirmDeleteDialogOpen(true)}>
                  Delete rate
                </Button>
              )}
            {deletingSchedule && (
              <Button variant="destructive" disabled>
                Deleting...
              </Button>
            )}
          </div>

          <div>
            {!savingSchedule && (
              <Button
                onClick={() => {
                  const isScheduleValid = validateSchedule();
                  if (isScheduleValid) {
                    setIsSetPriceDescriptorsOpen(true);
                  }
                }}
                disabled={selectedLocation === undefined || !unsavedChanges}>
                {newScheduleName ? 'Save New Rate' : 'Save Rate'}
              </Button>
            )}
            {savingSchedule && (
              <Button disabled>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Saving rate
              </Button>
            )}
          </div>
        </CardFooter>
      </Card>

      <NewRateTypeDialog
        open={!!newEventTimeSlot}
        onClose={() => setNewEventTimeSlot(null)}
        onSubmit={addRateEvent}
        timeSlot={newEventTimeSlot}
        dayStartOffset={dayStartOffset}
      />
      <EditRateTypeDialog
        open={!!editEvent}
        onClose={() => setEditEvent(null)}
        event={editEvent}
        onDeleteEvent={handleDeleteEvent}
        onSubmit={editRateEvent}
        dayStartOffset={dayStartOffset}
      />
      {isDayStartDialogOpen && (
        <ChangeDayStartDialog
          open={isDayStartDialogOpen}
          onClose={() => setIsDayStartDialogOpen(false)}
          onSubmit={changeDayStartOffset}
          currentOffset={dayStartOffset}
          currentWeekStart={currentWeekStart}
          eventsAreScheduled={!!events.length}
        />
      )}
      {isEFRDialogOpen && (
        <EditEFRDialog
          open={isEFRDialogOpen}
          onClose={() => setIsEFRDialogOpen(false)}
          efrs={locationEFRs}
          pendingChanges={pendingEFRChanges}
          setPendingChanges={(changes) => {
            !unsavedChanges && setUnsavedChanges(true);
            setPendingEFRChanges(changes);
          }}
        />
      )}
      {isGracePeriodDialogOpen && (
        <EditGracePeriodDialog
          open={isGracePeriodDialogOpen}
          onClose={() => setIsGracePeriodDialogOpen(false)}
          onSubmit={changeGracePeriod}
          currentGracePeriod={gracePeriod}
        />
      )}
      {isConfirmDeleteDialogOpen && (
        <ConfirmDeleteScheduleDialog
          scheduleName={selectableSchedules.find((el) => el.id === selectedScheduleId).name}
          open={isConfirmDeleteDialogOpen}
          onClose={() => setIsConfirmDeleteDialogOpen(false)}
          onSubmit={() => {
            setIsConfirmDeleteDialogOpen(false);
            handleDeleteSchedule();
          }}
        />
      )}
      <SetPriceDescriptorsDialog
        open={isSetPriceDescriptorsOpen}
        onClose={() => setIsSetPriceDescriptorsOpen(false)}
        onSubmit={(cleanedPriceDescriptors) => {
          setIsSetPriceDescriptorsOpen(false);
          handleSubmit(cleanedPriceDescriptors);
        }}
        locationName={selectableLocations.find((loc) => loc.location_id === selectedLocation)?.location_name}
        priceDescriptors={priceDescriptors}
        locationDetails={locationDetails}
        setPriceDescriptors={setPriceDescriptors}
      />
    </div>
  );
}
