import { CaretSortIcon, CheckIcon } from '@radix-ui/react-icons';
import { Button } from 'components/ui/button';
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from 'components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover';
import { cn } from 'lib/utils';
import { useState } from 'react';

export default function SelectLocationDropdown({ selectedLocation, onSelectLocation, selectableLocations }) {
  const [open, setOpen] = useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button variant="outline" role="combobox" aria-expanded={open} className="w-[140px] md:w-[240px] justify-between">
          <span className="truncate">
            {selectedLocation ? selectableLocations.find((loc) => loc.location_id === selectedLocation)?.location_name : 'Select a location...'}
          </span>
          <CaretSortIcon className="ml-auto h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[240px] p-0">
        <Command>
          <CommandList>
            <CommandInput placeholder="Search locations..." />
            <CommandEmpty>No location found.</CommandEmpty>

            <CommandGroup key="locations">
              {selectableLocations.map((loc) => (
                <CommandItem
                  key={loc.location_id}
                  onSelect={() => {
                    setOpen(false);
                    onSelectLocation(loc.location_id);
                  }}>
                  {loc.location_name}
                  <CheckIcon className={cn('ml-auto h-4 w-4', selectedLocation === loc.location_id ? 'opacity-100' : 'opacity-0')} />
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
