import { zodResolver } from '@hookform/resolvers/zod';
import { useFieldArray, useForm } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import { z } from 'zod';
import { Separator } from 'components/ui/separator';
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form';
import { Button } from 'components/ui/button';
import { Input } from 'components/ui/input';
import { Label } from 'components/ui/label';
import { RadioGroup, RadioGroupItem } from 'components/ui/radio-group';
import { Tabs, TabsList, TabsTrigger } from 'components/ui/tabs';

const MAX_NUM_SERVICE_FEES = 10;

const lpFormSchema = z.object({
  gracePeriod: z.preprocess(
    (x) => (x === null || x === undefined ? undefined : x),
    z.coerce
      .number({
        required_error: 'Please input a number of minutes.',
        invalid_type_error: 'Please input a number'
      })
      .min(0, {
        message: 'Cannot have a grace period of less than 0'
      })
      .max(1440, {
        message: 'Cannot have a grace period of more than 1440 mins (24 hours)'
      })
  ),
  managerEmail: z.preprocess((x) => (x === '' ? null : x), z.string().email({ message: 'Invalid email address' }).nullable()),
  fees: z
    .array(
      z.object({
        description: z.string({ required_error: 'Please enter a description' }).min(1, {
          message: 'Please enter a description'
        }),
        value: z.preprocess(
          (x) => {
            if (x !== null && x !== undefined) {
              return String(x).replace(/[\$,%]/g, '');
            }
            return x;
          },
          z.coerce
            .number({
              required_error: 'Please input a number',
              invalid_type_error: 'Please input a number'
            })
            .positive({
              message: 'Must be greater than 0'
            })
        ),
        type: z.string({ required_error: 'Please select a type' })
      })
    )
    .optional(),
  taxRate: z
    .object({
      included: z.boolean(),
      rate: z
        .preprocess(
          (x) => {
            if (x !== null && x !== undefined) {
              return String(x).replace(/[\$,%]/g, '');
            }
            return x;
          },
          z.coerce.number().max(100, { message: 'Tax rate cannot exceed 100%' })
        )
        .optional()
    })
    .superRefine(({ included, rate }, refinementContext) => {
      if (!included && !rate) {
        return refinementContext.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please enter a tax rate percentage',
          path: ['rate']
        });
      }
    })
});

export default function LightningPayTab({ defaultValues, onSubmit, disabled, canEdit }) {
  const form = useForm({
    resolver: zodResolver(lpFormSchema),
    defaultValues,
    mode: 'onChange'
  });

  const { fields, append, remove } = useFieldArray({
    name: 'fees',
    control: form.control
  });

  const onSubmitForm = async (data) => {
    // We only want to set a new tax rate if they've changed the tax rate, so we don't spam new stripe tax objects.
    // The backend will treat newTaxRatePercent like this:
    //    undefined - don't change
    //    null - set to null
    //    number - Create new stripe tax object with the given rate percent
    let newTaxRatePercent = null;
    if (!data.taxRate.included) {
      if (data.taxRate.rate !== defaultValues.taxRate.rate) {
        newTaxRatePercent = data.taxRate.rate;
      } else {
        newTaxRatePercent = undefined;
      }
    }

    const serviceFees = data.fees.map((fee) => ({
      descriptor: fee.description,
      flatAmount: fee.type === 'dollars' ? Math.round(fee.value * 100) : undefined,
      percentage: fee.type === 'percent' ? fee.value : undefined
    }));

    onSubmit({ ...data, newTaxRatePercent, serviceFees });
  };

  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium">Lightning Pay</h3>
        <p className="text-sm text-muted-foreground">Update your settings for the Lightning Pay system.</p>
      </div>
      <Separator />

      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmitForm)}>
          <div className="max-w-2xl text-left space-y-3 sm:space-y-8 mb-4">
            <FormField
              control={form.control}
              name="gracePeriod"
              render={({ field }) => (
                <FormItem>
                  <div className="flex justify-between items-center">
                    <div className="mb-3 mr-2 text-left">
                      <FormLabel>Grace Period</FormLabel>
                      <FormDescription>How many minutes can parkers park without being charged?</FormDescription>
                    </div>
                    <FormControl>
                      <Input placeholder="30" {...field} className="w-28" disabled={!canEdit} />
                    </FormControl>
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="managerEmail"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Manager Email</FormLabel>
                  <FormControl>
                    <Input placeholder="manager@example.com" {...field} disabled={!canEdit} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="pb-3">
              <FormField
                control={form.control}
                name="taxRate.included"
                render={({ field }) => (
                  <FormItem>
                    <div className="mb-3 text-left">
                      <FormLabel>Tax Rate</FormLabel>
                      <FormDescription>Please describe how tax is handled at this facility</FormDescription>
                    </div>
                    <FormControl>
                      <RadioGroup
                        onValueChange={(value) => field.onChange(value === 'included')}
                        value={field.value === undefined ? undefined : field.value ? 'included' : 'excluded'}
                        disabled={!canEdit}
                        className="space-y-1">
                        <FormItem className="flex items-center space-x-3 space-y-0">
                          <FormControl>
                            <RadioGroupItem value="included" />
                          </FormControl>
                          <FormLabel className="font-normal">Tax is included in my rates</FormLabel>
                        </FormItem>
                        <FormItem className="flex items-center space-x-3 space-y-0">
                          <FormControl>
                            <RadioGroupItem value="excluded" />
                          </FormControl>
                          <FormLabel className="font-normal">Tax is charged in addition to my rates</FormLabel>
                        </FormItem>
                      </RadioGroup>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              {form.watch('taxRate.included') === false && (
                <FormField
                  control={form.control}
                  name="taxRate.rate"
                  render={({ field }) => {
                    const { ref, ...rest } = field;

                    return (
                      <FormItem className="mt-3">
                        <div className="space-y-2 text-left justify-start">
                          <FormDescription>What additional tax percentage should be charged?</FormDescription>
                          <FormControl>
                            <NumericFormat
                              className="max-w-20"
                              {...rest}
                              customInput={Input}
                              allowLeadingZeros
                              thousandSeparator=","
                              suffix="%"
                              decimalScale={2}
                              allowNegative={false}
                              disabled={!canEdit}
                              placeholder="5%"
                            />
                          </FormControl>
                          <FormMessage />
                        </div>
                      </FormItem>
                    );
                  }}
                />
              )}
            </div>
            <div>
              <div className="text-start">
                <Label className="mb-2">Service Fees</Label>
                <p className="text-[0.8rem] text-muted-foreground mb-2">Add any fees that the parker needs to pay at your location</p>
              </div>
              <div>
                {fields.map((field, index) => (
                  <div key={field.id} className="flex mb-2">
                    {/* Fee Description Input */}
                    <FormField
                      control={form.control}
                      name={`fees.${index}.description`}
                      render={({ field }) => (
                        <FormItem className="mr-2 flex-grow">
                          <FormControl>
                            <Input {...field} placeholder="Fee Description" disabled={!canEdit} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    {/* Fee Value Input */}
                    <FormField
                      control={form.control}
                      name={`fees.${index}.value`}
                      render={({ field }) => {
                        const { ref, ...rest } = field;

                        return (
                          <FormItem className="w-24 mr-2">
                            <FormControl>
                              {form.watch(`fees.${index}.type`) === 'percent' ? (
                                <NumericFormat
                                  {...rest}
                                  disabled={!canEdit}
                                  customInput={Input}
                                  allowLeadingZeros
                                  thousandSeparator=","
                                  suffix="%"
                                  decimalScale={2}
                                  allowNegative={false}
                                  placeholder="5%"
                                />
                              ) : (
                                <NumericFormat
                                  {...rest}
                                  disabled={!canEdit}
                                  customInput={Input}
                                  allowLeadingZeros
                                  thousandSeparator=","
                                  prefix="$"
                                  decimalScale={2}
                                  fixedDecimalScale
                                  allowNegative={false}
                                  placeholder="$5.00"
                                />
                              )}
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        );
                      }}
                    />

                    <FormField
                      control={form.control}
                      name={`fees.${index}.type`}
                      render={({ field }) => (
                        <FormItem className="w-24">
                          <Tabs defaultValue="dollars" onValueChange={field.onChange} value={field.value} className="w-full">
                            <TabsList className="grid w-full grid-cols-2">
                              <TabsTrigger value="dollars">$</TabsTrigger>
                              <TabsTrigger value="percent">%</TabsTrigger>
                            </TabsList>
                          </Tabs>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                ))}
                <div className="max-sm:w-[170px]">
                  <Button
                    type="button"
                    variant="secondary"
                    size="sm"
                    className="mt-2 mr-2"
                    onClick={() => remove(fields.length - 1)}
                    disabled={!fields.length || !canEdit}>
                    Remove fee
                  </Button>
                  <Button
                    type="button"
                    variant="outline"
                    size="sm"
                    className="mt-2"
                    onClick={() => append({ description: 'Technology Charge', value: '', type: 'dollars' })}
                    disabled={fields.length >= MAX_NUM_SERVICE_FEES || !canEdit}>
                    Add fee
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {canEdit && (
            <Button type="submit" className="mt-4" disabled={disabled}>
              Update
            </Button>
          )}
        </form>
      </Form>
    </div>
  );
}
