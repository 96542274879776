import { flexRender, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { Button } from 'components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'components/ui/card';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from 'components/ui/dropdown-menu';
import { Input } from 'components/ui/input';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/ui/table';
import { useToast } from 'components/ui/use-toast';
import { ArrowUpDown, CirclePlus, MoreHorizontal, Loader2 } from 'lucide-react';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../context/provider';
import { getLocationsDetailsAPI } from '../../services/ManageLocations';
import { access, shortAccess, shortFeatureNames } from '../../util/FeatureTypes';

export default function ListLocations() {
  const [sorting, setSorting] = useState([]);
  const isSmallScreen = window.innerWidth <= 640;
  const [columnVisibility, setColumnVisibility] = useState({ state: !isSmallScreen, city: !isSmallScreen, id: false, canEdit: false });
  const [rowSelection, setRowSelection] = useState({});
  const history = useHistory();
  const { permissions } = useContext(AppContext);
  const [locations, setLocations] = useState([]);
  const [loadingLocations, setLoadingLocations] = useState(true);
  const { toast } = useToast();

  const canAddLocation = permissions?.featureLevelLocations?.default?.[shortFeatureNames.LOCATION]?.[shortAccess[access.CAN_ADD]];

  useEffect(() => {
    const getLocationsDetails = async () => {
      // TODO which permissions are needed for adding or editing locations
      const viewableLocations = permissions.locationLocations
        .filter((lot) => permissions.locationsPermissions[lot.location_id]?.canView ?? false)
        .map((lot) => lot.location_id);

      setLoadingLocations(true);
      try {
        const { success, data } = await getLocationsDetailsAPI({
          payload: {
            locations: viewableLocations
          }
        });
        if (!success || !data) {
          throw new Error('Unable to get location details');
        }
        setLocations(data.allDetails?.map((loc) => ({ ...loc, canEdit: permissions.locationsPermissions[loc.id]?.canEdit ?? false })));
      } catch (error) {
        console.error('Error:', error);
        toast({
          title: 'Uh oh! Something went wrong.',
          description: 'We were unable to get your locations. Please let us know!'
        });
      }
      setLoadingLocations(false);
    };
    if (!locations?.length) {
      getLocationsDetails();
    }
  }, [permissions, locations]);

  const [globalFilter, setGlobalFilter] = useState('');

  const columns = useMemo(() => {
    const cols = [
      {
        accessorKey: 'id'
      },
      {
        accessorKey: 'canEdit'
      },
      {
        accessorKey: 'name',
        size: 400,
        header: ({ column }) => (
          <Button className="px-2" variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
            Name
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: ({ row }) => <div className="p-2">{row.getValue('name')}</div>
      },
      {
        accessorKey: 'city',
        size: 150,
        header: 'City',
        cell: ({ row }) => <div>{row.getValue('city')}</div>
      },
      {
        accessorKey: 'state',
        size: 50,
        header: ({ column }) => (
          <Button className="px-2" variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
            State
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        ),
        cell: ({ row }) => <div>{row.getValue('state')}</div>
      },
      {
        id: 'actions',
        enableHiding: false,
        size: 50,
        cell: ({ row }) => (
          <div className="text-end mr-2">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="h-8 w-8 p-0">
                  <span className="sr-only">Open menu</span>
                  <MoreHorizontal className="h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem
                  className="hover:cursor-pointer"
                  onClick={() => history.push(`/edit-location/${row.getValue('id')}?name=${encodeURIComponent(row.getValue('name'))}`)}>
                  {row.getValue('canEdit') ? 'Edit' : 'View'}
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        )
      }
    ];

    return cols;
  }, [history, loadingLocations]);

  const globalFilterFn = (row, columnId, filterValue) => {
    const searchValue = filterValue.toLowerCase();
    const name = row.getValue('name').toLowerCase();
    const state = row.getValue('state').toLowerCase();
    return name.includes(searchValue) || state.includes(searchValue);
  };

  const table = useReactTable({
    data: locations,
    columns,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    globalFilterFn,
    state: {
      sorting,
      columnVisibility,
      rowSelection,
      globalFilter
    },
    onGlobalFilterChange: setGlobalFilter
  });

  return (
    <div>
      <Card className="sm:px-8">
        <CardHeader className="pb-0 sm:mb-4 pt-3">
          <CardTitle className="text-xl md:text-2xl">Locations</CardTitle>
          <CardDescription className="max-sm:hidden">Manage your Lightning Pay locations.</CardDescription>
        </CardHeader>
        <CardContent className="text-left">
          <div className="w-full">
            <div className="flex items-center py-4 space-x-3">
              <Input
                placeholder="Filter locations..."
                value={globalFilter ?? ''}
                onChange={(event) => table.setGlobalFilter(event.target.value)}
                className="max-w-sm"
              />
              {canAddLocation && (
                <Button variant="outline" size="sm" className="h-8 border-dashed" onClick={() => history.push('/add-location')}>
                  <CirclePlus className="mr-2 h-4 w-4" />
                  New Location
                </Button>
              )}
            </div>
            <div className="rounded-md border">
              <Table>
                <TableHeader>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <TableRow key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <TableHead
                          key={header.id}
                          colSpan={header.colSpan}
                          style={
                            isSmallScreen
                              ? {}
                              : {
                                  minWidth: header.getSize() ? header.getSize() : 0
                                }
                          }>
                          {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                        </TableHead>
                      ))}
                    </TableRow>
                  ))}
                </TableHeader>
                <TableBody>
                  {loadingLocations ? (
                    <TableRow>
                      <TableCell colSpan={columns.length} className="h-24 text-center">
                        <Loader2 className="mr-2 h-4 w-4 animate-spin inline" />
                        Loading locations...
                      </TableCell>
                    </TableRow>
                  ) : table.getRowModel().rows?.length ? (
                    table.getRowModel().rows.map((row) => (
                      <TableRow key={row.id} data-state={row.getIsSelected() && 'selected'}>
                        {row.getVisibleCells().map((cell) => (
                          <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                        ))}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={columns.length} className="h-24 text-center">
                        No results.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
            <div className="flex items-center justify-end space-x-2 py-4">
              <div className="flex-1 text-sm text-muted-foreground">
                Page {(table.getState().pagination.pageIndex ?? 0) + 1} of {table.getPageCount()}
              </div>
              <div className="space-x-2">
                <Button variant="outline" size="sm" onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
                  Previous
                </Button>
                <Button variant="outline" size="sm" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
                  Next
                </Button>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
