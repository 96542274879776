import { zodResolver } from '@hookform/resolvers/zod';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { MultipleSelector } from 'components/ui/multiple-selector';
import { Separator } from 'components/ui/separator';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { Button } from 'components/ui/button';
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form';
import { Input } from 'components/ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select';
import { searchZephireLevelsAPI } from '../../services/ManageLocations';
import { allStatesList } from '../AddLocation/additionalData';

const mapStyle = {
  height: '100%',
  width: '100%',
  cursor: 'default'
};
const NEW_YORK_CITY_COORDS = {
  lat: 40.7128,
  lng: -74.006
};
const DEFAULT_ZOOM = 5;
const ON_LOCATION_SEARCH_ZOOM = 18;

const multiSelectOptionSchema = z.object({
  label: z.string(),
  value: z.union([z.string(), z.number()]),
  disable: z.boolean().optional()
});

const generalFormSchema = z.object({
  locationName: z
    .string()
    .min(2, {
      message: 'Location name must be at least 2 characters.'
    })
    .max(50, {
      message: 'Location name must not be longer than 50 characters.'
    }),
  address: z
    .string()
    .min(2, {
      message: 'Please enter an address.'
    })
    .max(200),
  fullAddress: z.string().optional(),
  coords: z
    .object({
      lat: z.number(),
      lng: z.number()
    })
    .refine((value) => value !== undefined, {
      message: 'Please select a point on the map'
    }),
  city: z
    .string()
    .min(2, {
      message: 'Please enter a city.'
    })
    .max(200),
  state: z.string({
    required_error: 'Please select a state.'
  }),
  zephireLevels: z.array(multiSelectOptionSchema)
});

const getStreetAddress = (addressComponents) => {
  let streetNumber = '';
  let streetName = '';

  if (!addressComponents) {
    return undefined;
  }

  addressComponents.forEach((component) => {
    if (component.types.includes('street_number')) {
      streetNumber = component.long_name;
    }
    if (component.types.includes('route')) {
      streetName = component.long_name;
    }
  });

  if (!streetNumber && !streetName) {
    return undefined;
  }

  return `${streetNumber} ${streetName}`;
};

const getCityAndState = (addressComponents) => {
  const properties = {};
  if (!addressComponents) {
    return properties;
  }

  const state = addressComponents.find((el) => el.types?.includes('administrative_area_level_1'));
  if (state?.short_name) {
    properties.state = state.short_name;
  }

  const city = addressComponents.find((el) => el.types?.includes('locality'));
  if (city?.long_name) {
    properties.city = city.long_name;
  }
  return properties;
};

export default function GeneralTab({ defaultValues, onSubmit, disabled, canEdit }) {
  const [markerPosition, setMarkerPosition] = useState(undefined);
  const [map, setMap] = useState(null);
  const [timezoneData, setTimezoneData] = useState(null);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_PLACES_API_KEY
  });
  const autoCompleteRef = useRef(null);
  const [searchInputValue, setSearchInputValue] = useState('');

  useEffect(() => {
    if (map && defaultValues.coords) {
      map.setCenter(defaultValues.coords);
      map.setZoom(ON_LOCATION_SEARCH_ZOOM);
      setMarkerPosition(defaultValues.coords);
    }
  }, [defaultValues.coords, map]);

  const form = useForm({
    resolver: zodResolver(generalFormSchema),
    defaultValues,
    mode: 'onChange'
  });
  const inputRef = useRef();

  const fetchTimezoneData = async (latitude, longitude) => {
    try {
      const apiKey = process.env.REACT_APP_GOOGLE_PLACES_API_KEY;
      const currentUnixTimestampInSeconds = Math.floor(Date.now() / 1000);
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/timezone/json?location=${latitude},${longitude}&timestamp=${currentUnixTimestampInSeconds}&key=${apiKey}`
      );
      if (!response?.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setTimezoneData(data);
    } catch (error) {
      console.error('There was a problem with your fetch operation:', error);
    }
  };

  const onLoad = useCallback(
    (map) => {
      setMap(map);
    },
    [setMap]
  );

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const onSubmitForm = (data) => {
    // Compare current zephireLevels with defaultValues. Only update zephireLevels if they've changed.
    const currentLevels = data?.zephireLevels
      ?.map((level) => level.value)
      .sort()
      .join(',');
    const defaultLevels = defaultValues?.zephireLevels
      ?.map((level) => level.value)
      .sort()
      .join(',');

    const zephireLevels =
      currentLevels !== defaultLevels ? data?.zephireLevels?.map((level) => ({ level: level.value, name: level.label })) : undefined;

    onSubmit({ ...data, zephireLevels, timezone: timezoneData?.timeZoneId ?? undefined });
  };

  useEffect(() => {
    if (!autoCompleteRef.current && isLoaded && form && map && window.google && window.google.maps && window.google.maps.places) {
      autoCompleteRef.current = new window.google.maps.places.Autocomplete(inputRef.current, { componentRestrictions: { country: ['US'] } });
      autoCompleteRef.current.addListener('place_changed', () => {
        const place = autoCompleteRef.current.getPlace();
        if (!place.geometry || !place.geometry.location) {
          // User entered the name of a Place that was not suggested and
          // pressed the Enter key, or the Place Details request failed.
        }
        if (place.geometry && place.geometry.location) {
          const lat = place.geometry.location.lat();
          const lng = place.geometry.location.lng();
          fetchTimezoneData(lat, lng);
          const streetAddress = getStreetAddress(place.address_components);
          const cityState = getCityAndState(place.address_components);
          form.setValue('address', streetAddress);
          form.setValue('city', cityState.city);
          form.setValue('state', cityState.state);
          form.setValue('fullAddress', place.formatted_address ?? null);
          form.setValue('coords', { lat, lng });
          map.setCenter(place.geometry.location);
          map.setZoom(ON_LOCATION_SEARCH_ZOOM);
          setMarkerPosition(place.geometry.location);
        } else {
          setMarkerPosition(null);
        }
      });
    }
  }, [inputRef, form, map, isLoaded]);

  const searchZephireLevels = async (searchValue) => {
    try {
      const { success, data } = await searchZephireLevelsAPI(searchValue);
      if (!success || !data) {
        throw new Error('Unable to get Zephire levels');
      }
      return data;
    } catch (error) {
      console.error('Error searching Zephire levels:', error);
      return [];
    }
  };

  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium">General</h3>
        <p className="text-sm text-muted-foreground">Update your locations general settings.</p>
      </div>
      <Separator />
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmitForm)}>
          <div className="max-w-2xl space-y-3 sm:space-y-8 text-left">
            <FormField
              control={form.control}
              name="locationName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Lot Name</FormLabel>
                  <FormControl>
                    <Input placeholder="My New Lot" {...field} disabled={!canEdit} />
                  </FormControl>
                  <FormDescription>
                    This is the name which will be displayed to parkers when they go to pay for parking. Please make sure it ends in "Lot" or
                    "Garage".
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="zephireLevels"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Zephire Level(s)</FormLabel>
                  <FormDescription>Search and select Zephire Level IDs connected to this location</FormDescription>
                  <FormControl>
                    <MultipleSelector
                      {...field}
                      hidePlaceholderWhenSelected
                      onSearch={async (value) => {
                        setSearchInputValue(value);
                        const results = await searchZephireLevels(value);
                        return results;
                      }}
                      placeholder="Start typing to search Zephire levels..."
                      disabled={!canEdit}
                      loadingIndicator={
                        <div className="flex items-center justify-center py-8">
                          <div className="flex items-center space-x-2">
                            <div className="h-4 w-4 animate-spin rounded-full border-2 border-primary border-t-transparent"></div>
                            <p className="text-sm text-muted-foreground">Searching Zephire levels...</p>
                          </div>
                        </div>
                      }
                      emptyIndicator={
                        <div className="flex items-center justify-center py-2 text-center w-full">
                          <p className="text-sm text-muted-foreground">
                            {searchInputValue ? 'No Zephire levels found matching your search.' : 'Type to search for Zephire levels...'}
                          </p>
                        </div>
                      }
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div>
              <FormField
                control={form.control}
                name="fullAddress"
                render={() => (
                  <FormItem>
                    <FormLabel>Lot Location</FormLabel>
                    <FormControl>
                      <Input
                        id="searchloc"
                        placeholder={defaultValues.fullAddress ?? 'Enter an address'}
                        disabled={!canEdit}
                        ref={inputRef}
                        className="mb-4"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="coords"
                render={({ field }) => (
                  <FormItem>
                    {isLoaded ? (
                      <div className="h-[250px] rounded-md overflow-hidden ">
                        <GoogleMap
                          onLoad={onLoad}
                          center={NEW_YORK_CITY_COORDS}
                          zoom={DEFAULT_ZOOM}
                          mapContainerStyle={mapStyle}
                          onClick={(props) => {
                            setMarkerPosition(props.latLng);
                            const lat = props.latLng.lat();
                            const lng = props.latLng.lng();
                            fetchTimezoneData(lat, lng);
                            field.onChange({ lat, lng });
                          }}
                          onUnmount={onUnmount}
                          clickableIcons={false}
                          options={{
                            draggableCursor: 'default'
                          }}>
                          <Marker position={markerPosition} />
                        </GoogleMap>
                      </div>
                    ) : (
                      <></>
                    )}
                    <FormDescription>Place the marker at the entrance to your lot.</FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <FormField
              control={form.control}
              name="address"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Lot Address</FormLabel>
                  <FormControl>
                    <Input placeholder="123 Example St" {...field} disabled={!canEdit} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="city"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>City</FormLabel>
                  <FormControl>
                    <Input placeholder="New York" {...field} disabled={!canEdit} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="state"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>State</FormLabel>
                  <Select onValueChange={field.onChange} value={field.value}>
                    <FormControl>
                      <SelectTrigger disabled={!canEdit}>
                        <SelectValue placeholder="Select a state" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {allStatesList.map((state) => (
                        <SelectItem key={state.code} value={state.code}>
                          {state.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          {canEdit && (
            <Button type="submit" className="mt-6" disabled={disabled}>
              Update
            </Button>
          )}
        </form>
      </Form>
    </div>
  );
}
