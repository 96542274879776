import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { Button } from 'components/ui/button';
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form';
import { Input } from 'components/ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select';
import { useStepper } from 'components/ui/stepper';
import { useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { allStatesList, basicUsTimezonesMapping } from './additionalData';
import { getLocationIdAvailabilityAPI } from '../../services/AddLocation';

const locationDetailsFormSchema = z.object({
  locationName: z
    .string()
    .min(2, {
      message: 'Location name must be at least 2 characters.'
    })
    .max(50, {
      message: 'Location name must not be longer than 50 characters.'
    }),
  locationId: z.preprocess(
    (x) => {
      if (x === '') {
        return undefined;
      }
      if (x !== null && x !== undefined) {
        return String(x).replace(/[\$,%]/g, '');
      }
      return x;
    },
    z.coerce.number({
      required_error: 'Please input a number',
      invalid_type_error: 'Please input a number'
    })
  ),
  address: z
    .string()
    .min(2, {
      message: 'Please enter an address.'
    })
    .max(200),
  city: z
    .string()
    .min(2, {
      message: 'Please enter a city.'
    })
    .max(200),
  state: z.string({
    required_error: 'Please select a state.'
  }),
  timezone: z.string({
    required_error: 'Please select a timezone.'
  })
});

export default function LocationDetailsForm({ defaultValues, onSubmitCallback }) {
  const [isLocationIdAvailable, setIsLocationIdAvailable] = useState(true);
  const [isCheckingId, setIsCheckingId] = useState(false);
  const { nextStep, prevStep } = useStepper();
  const form = useForm({
    resolver: zodResolver(locationDetailsFormSchema),
    defaultValues,
    mode: 'onChange'
  });

  function onSubmit(data) {
    if (isLocationIdAvailable) {
      onSubmitCallback(data);
      nextStep();
    }
  }

  const handleLocationIdChange = async (value) => {
    if (value) {
      setIsCheckingId(true);
      let isAvailable = false;
      try {
        const { success, data } = await getLocationIdAvailabilityAPI(Number(value));
        if (!success || !data) {
          throw new Error('Unable to get locationId availability');
        }
        isAvailable = data.available;
      } catch (e) {
        console.error('Error getting locationId availability:', e.message);
      }

      if (form.watch('locationId') === value) {
        setIsLocationIdAvailable(isAvailable);
        setIsCheckingId(false);
      }
    } else {
      setIsLocationIdAvailable(true);
      setIsCheckingId(false);
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="max-w-2xl space-y-3 sm:space-y-8 py-6 text-left">
          <FormField
            control={form.control}
            name="locationName"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Location Name</FormLabel>
                <FormControl>
                  <Input placeholder="My New Lot" {...field} />
                </FormControl>
                <FormDescription>
                  This is the name which will be displayed to parkers when they go to pay for parking. Please make sure it ends in "Lot or "Garage".
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="locationId"
            render={({ field }) => {
              const { ref, onChange, ...rest } = field;
              return (
                <FormItem className="mt-3">
                  <div className="space-y-2 text-left justify-start">
                    <FormLabel className={`${!isLocationIdAvailable && !isCheckingId && 'text-destructive'}`}>Location ID</FormLabel>
                    <FormControl>
                      <NumericFormat
                        className="max-w-40"
                        {...rest}
                        customInput={Input}
                        decimalScale={0}
                        allowNegative={false}
                        placeholder="Location ID"
                        onValueChange={(values) => {
                          onChange(values.value);
                          handleLocationIdChange(values.value);
                        }}
                      />
                    </FormControl>
                    {isCheckingId && <p className="text-[0.8rem] font-medium text-muted-foreground">Checking availability...</p>}
                    {!isLocationIdAvailable && !isCheckingId && (
                      <p className="text-[0.8rem] font-medium text-destructive">This Location ID has already been created.</p>
                    )}
                    {isLocationIdAvailable && !isCheckingId && field.value && (
                      <p className="text-[0.8rem] font-medium text-green-500">This Location ID is available.</p>
                    )}
                    <FormMessage />
                  </div>
                </FormItem>
              );
            }}
          />
          <FormField
            control={form.control}
            name="address"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Lot Address</FormLabel>
                <FormControl>
                  <Input placeholder="123 Example St" {...field} />
                </FormControl>
                {/* <FormDescription>
                This is the address of your lot.
              </FormDescription> */}
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="city"
            render={({ field }) => (
              <FormItem>
                <FormLabel>City</FormLabel>
                <FormControl>
                  <Input placeholder="New York" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="state"
            render={({ field }) => (
              <FormItem>
                <FormLabel>State</FormLabel>
                <Select onValueChange={field.onChange} defaultValue={field.value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select a state" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {allStatesList.map((state) => (
                      <SelectItem key={state.code} value={state.code}>
                        {state.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                {/* <FormDescription>You can manage verified email addresses in your</FormDescription> */}
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="timezone"
            render={({ field }) => (
              <FormItem>
                {!defaultValues.timezone && (
                  <div>
                    <FormLabel>Timezone</FormLabel>
                    <Select onValueChange={field.onChange} defaultValue={field.value}>
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Select a timezone" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {Object.entries(basicUsTimezonesMapping).map(([timezoneName, timezoneDisplayName]) => (
                          <SelectItem key={timezoneName} value={timezoneName}>
                            {timezoneDisplayName}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                )}
              </FormItem>
            )}
          />
        </div>
        <div className="flex justify-between max-sm:mx-2">
          <Button onClick={prevStep} variant="secondary">
            Previous
          </Button>
          <Button type="submit" disabled={isCheckingId}>
            Continue
          </Button>
        </div>
      </form>
    </Form>
  );
}
