import api from './api';

export const upsertRateScheduleAPI = async ({ payload }) => api({ url: '/rate-schedule/edit-schedule', method: 'post', payload });
export const getRateSchedulesAPI = async ({ locationId }) => api({ url: `/rate-schedule/get-schedules/${locationId}`, method: 'get' });
export const getEFRsAPI = async ({ locationId }) => api({ url: `/rate-schedule/get-efrs/${locationId}`, method: 'get' });
export const updateEFRsAPI = async ({ payload }) => api({ url: '/rate-schedule/update-efrs', method: 'post', payload });
export const updateRateResetTimeAPI = async ({ payload }) => api({ url: '/rate-schedule/update-default-rate-reset-time', method: 'post', payload });
export const getLocationRateResetTimesAPI = async ({ locationId }) =>
  api({ url: `/rate-schedule/get-location-rate-reset-times/${locationId}`, method: 'get' });
export const deleteRateScheduleAPI = async ({ payload }) => api({ url: '/rate-schedule/delete-schedule', method: 'post', payload });
export const getLocationPriceDescriptorsAPI = async ({ locationId }) =>
  api({ url: `/rate-schedule/get-location-price-descriptors/${locationId}`, method: 'get' });
export const updateRateStructurePriceDescriptorsAPI = async ({ payload }) =>
  api({ url: '/rate-schedule/update-rate-structure-price-descriptors', method: 'post', payload });
