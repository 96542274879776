import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle
} from 'components/ui/alert-dialog';
import { Button } from 'components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from 'components/ui/dialog';

import { Form, FormControl, FormField, FormItem } from 'components/ui/form';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { convertDayStartTimeToOffset, convertOffsetToDayStartTime } from './helpers';

export default function ChangeDayStartDialog({ open, onClose, onSubmit, currentOffset, currentWeekStart, eventsAreScheduled }) {
  const [confirm, setConfirm] = useState(false);
  const [proposedSubmission, setProposedSubmission] = useState(null);
  const form = useForm({
    defaultValues: { ...convertOffsetToDayStartTime(currentOffset), weekstart: currentWeekStart.toString() }
  });

  const onSubmitForm = (data) => {
    const offset = convertDayStartTimeToOffset(data);

    if (!eventsAreScheduled) {
      onSubmit(offset, parseInt(data.weekstart));
    } else {
      setProposedSubmission({ offset, weekStart: parseInt(data.weekstart) });
      setConfirm(true);
    }
  };

  const onConfirmSubmit = () => {
    setProposedSubmission(null);
    setConfirm(false);
    onSubmit(proposedSubmission.offset, proposedSubmission.weekStart);
  };

  const closeDialog = () => {
    form.reset();
    setProposedSubmission(null);
    setConfirm(false);
    onClose();
  };

  return (
    <div>
      <Dialog open={open} onOpenChange={(isOpen) => !isOpen && closeDialog()}>
        <DialogContent className="sm:max-w-[425px] gap-0">
          <DialogHeader className="mb-4">
            <DialogTitle>Change day and week start </DialogTitle>
          </DialogHeader>

          <p className="text-sm text-muted-foreground text-center mb-2">Calendar days will start at the following time:</p>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmitForm)}>
              <div className="flex justify-center mb-6 mt-0">
                <FormField
                  control={form.control}
                  name="hrs"
                  render={({ field }) => (
                    <FormItem className="mx-1">
                      <Select onValueChange={field.onChange} defaultValue={field.value}>
                        <FormControl>
                          <SelectTrigger className="w-[80px]">
                            <SelectValue />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectGroup>
                            <SelectItem value="01">01</SelectItem>
                            <SelectItem value="02">02</SelectItem>
                            <SelectItem value="03">03</SelectItem>
                            <SelectItem value="04">04</SelectItem>
                            <SelectItem value="05">05</SelectItem>
                            <SelectItem value="06">06</SelectItem>
                            <SelectItem value="07">07</SelectItem>
                            <SelectItem value="08">08</SelectItem>
                            <SelectItem value="09">09</SelectItem>
                            <SelectItem value="10">10</SelectItem>
                            <SelectItem value="11">11</SelectItem>
                            <SelectItem value="12">12</SelectItem>
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="mins"
                  render={({ field }) => (
                    <FormItem className="mx-1">
                      <Select onValueChange={field.onChange} defaultValue={field.value}>
                        <FormControl>
                          <SelectTrigger className="w-[80px]">
                            <SelectValue />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectGroup>
                            <SelectItem value="00">00</SelectItem>
                            <SelectItem value="05">05</SelectItem>
                            <SelectItem value="10">10</SelectItem>
                            <SelectItem value="15">15</SelectItem>
                            <SelectItem value="20">20</SelectItem>
                            <SelectItem value="25">25</SelectItem>
                            <SelectItem value="30">30</SelectItem>
                            <SelectItem value="35">35</SelectItem>
                            <SelectItem value="40">40</SelectItem>
                            <SelectItem value="45">45</SelectItem>
                            <SelectItem value="50">50</SelectItem>
                            <SelectItem value="55">55</SelectItem>
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="ampm"
                  render={({ field }) => (
                    <FormItem className="mx-1">
                      <Select onValueChange={field.onChange} defaultValue={field.value}>
                        <FormControl>
                          <SelectTrigger className="w-[80px]">
                            <SelectValue />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectGroup>
                            <SelectItem value="AM">AM</SelectItem>
                            {/* <SelectItem value="PM">PM</SelectItem> */}
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                    </FormItem>
                  )}
                />
              </div>

              <p className="text-sm text-muted-foreground text-center mb-2">Calendar weeks will start on the following day:</p>
              <div className="flex justify-center">
                <FormField
                  control={form.control}
                  name="weekstart"
                  render={({ field }) => (
                    <FormItem className="mx-1">
                      <Select onValueChange={field.onChange} defaultValue={field.value}>
                        <FormControl>
                          <SelectTrigger className="w-[180px]">
                            <SelectValue />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectGroup>
                            <SelectItem value="0">Sunday</SelectItem>
                            <SelectItem value="1">Monday</SelectItem>
                            <SelectItem value="2">Tuesday</SelectItem>
                            <SelectItem value="3">Wednesday</SelectItem>
                            <SelectItem value="4">Thursday</SelectItem>
                            <SelectItem value="5">Friday</SelectItem>
                            <SelectItem value="6">Saturday</SelectItem>
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                    </FormItem>
                  )}
                />
              </div>

              <div className="flex justify-end mt-6">
                <Button type="submit">Set Day and Time</Button>
              </div>
            </form>
          </Form>
        </DialogContent>
      </Dialog>

      <AlertDialog open={confirm}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This will delete your current weekly schedule and you'll have to set your schedule again starting from a blank calendar.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={closeDialog}>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={onConfirmSubmit}>Continue</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
}
