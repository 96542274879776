import { Button } from 'components/ui/button';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from 'components/ui/dialog';
import { Input } from 'components/ui/input';
import { useState } from 'react';

const MAX_GRACE_PERIOD = 1440;

export default function EditGracePeriodDialog({ open, onClose, onSubmit, currentGracePeriod }) {
  const [gracePeriod, setGracePeriod] = useState(currentGracePeriod ?? 0);

  const handleGracePeriodInputChange = (event) => {
    const value = event.target.value;

    // Ensure the input is a number
    if (/^\d*\.?\d*$/.test(value)) {
      const clampedValue = Math.min(MAX_GRACE_PERIOD, Math.max(0, value));
      // Update the state with the clamped value
      setGracePeriod(clampedValue);
    } else if (gracePeriod === undefined) {
      setGracePeriod(0);
    }
  };

  return (
    <Dialog open={open} onOpenChange={(isOpen) => !isOpen && onClose()}>
      <DialogContent className="sm:max-w-[500px]">
        <DialogHeader>
          <DialogTitle>Edit grace period</DialogTitle>
        </DialogHeader>
        <p className="text-sm text-muted-foreground text-center ">
          If the park duration is less than the specified number of minutes, no charges will apply:
        </p>

        <div className="flex items-center justify-center mb-2">
          <Input className="w-16 mr-2" value={gracePeriod} onChange={handleGracePeriodInputChange} />
          <p>minutes</p>
        </div>

        <DialogFooter>
          <Button type="submit" onClick={() => onSubmit(gracePeriod ? gracePeriod : null)}>
            Set Grace Period
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
