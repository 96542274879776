import api from './api';

export const getLocationsDetailsAPI = async ({ payload }) => api({ url: '/manage-locations/get-locations-details/', method: 'post', payload });
export const addLocationPhotoAPI = async ({ payload }) => api({ url: '/manage-locations/add-location-photo/', method: 'post', payload });
export const deleteLocationPhotoAPI = async ({ payload }) => api({ url: '/manage-locations/delete-location-photo/', method: 'post', payload });
export const reorderLocationPhotoAPI = async ({ payload }) => api({ url: '/manage-locations/reorder-location-photos/', method: 'post', payload });
export const getPhotosForLocationAPI = async ({ locationId }) =>
  api({ url: `/manage-locations/get-photos-for-location/${locationId}`, method: 'get' });
export const getLocationDetailsAPI = async ({ locationId }) => api({ url: `/manage-locations/get-location-details/${locationId}`, method: 'get' });
export const getGateSettingsAPI = async ({ locationId }) => api({ url: `/manage-locations/get-gate-settings/${locationId}`, method: 'get' });
export const updateLocationsDetailsAPI = async ({ payload }) => api({ url: '/manage-locations/update-location-details/', method: 'post', payload });
export const getAllAmenitiesAPI = async () => api({ url: `/manage-locations/get-all-amenities/`, method: 'get' });
export const getAllParkingInfoAPI = async () => api({ url: `/manage-locations/get-all-parking-info/`, method: 'get' });
export const getLocationTRDetailsAPI = async ({ locationId }) =>
  api({ url: `/manage-locations/get-location-tr-details/${locationId}`, method: 'get' });
export const updateLocationTRDetailsAPI = async ({ payload }) =>
  api({ url: '/manage-locations/update-location-tr-details/', method: 'post', payload });
export const updateTREnabledStatusAPI = async ({ payload }) =>
  api({ url: '/manage-locations/update-is-tr-enabled-status/', method: 'post', payload });
export const updateGateSettingsAPI = async ({ payload }) => api({ url: '/manage-locations/update-gate-settings/', method: 'post', payload });
export const updateSignAPI = async ({ payload }) => api({ url: '/manage-locations/update-sign/', method: 'post', payload });
export const searchZephireLevelsAPI = async (searchValue) =>
  api({ url: `/manage-locations/search-zephire-levels?search=${searchValue}`, method: 'get' });
