import { Button } from 'components/ui/button';
import { Input } from 'components/ui/input';
import { Label } from 'components/ui/label';
import { Separator } from 'components/ui/separator';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/ui/table';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select';
import { useEffect, useState } from 'react';
import { getParticleDevicesAPI } from '../../services/AddLocation';
import { updateSignAPI } from '../../services/ManageLocations';
import { useToast } from 'components/ui/use-toast';

export default function InfoTab({ values, qrUrls, refreshLocationSettings, locationId }) {
  const { toast } = useToast();
  const [editState, setEditState] = useState({});
  const [particleDevices, setParticleDevices] = useState(undefined);
  const [selectedGatekit, setSelectedGatekit] = useState(
    values.signs.reduce((acc, sign) => {
      acc[sign.signId] = sign.gatekitId || '';
      return acc;
    }, {})
  );
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    const fetchParticleDevices = async () => {
      try {
        const { success, data } = await getParticleDevicesAPI(locationId);
        if (!success || !data) {
          throw new Error('Unable to get particle devices');
        }
        setParticleDevices(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchParticleDevices();
  }, []);

  const handleEditClick = (signId) => {
    setEditState((prevState) => ({
      ...prevState,
      [signId]: !prevState[signId]
    }));
  };

  const handleSaveClick = async (signId) => {
    setIsSaving(true);
    try {
      const selectedSignDevice = particleDevices.find((el) => el.id === selectedGatekit[signId]);

      if (!selectedSignDevice) {
        throw new Error(`Unable to find selected particle device: ${selectedGatekit[signId]}`);
      }

      const { success } = await updateSignAPI({
        payload: {
          signId,
          details: {
            laneName: selectedSignDevice.name,
            gatekitId: selectedSignDevice.id
          }
        }
      });
      if (!success) throw new Error(`Unable to save updates to sign ${signId}`);
      toast({
        title: `Changes saved!`
      });
    } catch (error) {
      console.error('Error:', error);
      toast({
        title: 'Uh oh! Something went wrong.',
        description: 'We were unable to update your sign. Please let us know!'
      });
    }
    setIsSaving(false);

    setEditState((prevState) => ({
      ...prevState,
      [signId]: false
    }));
    refreshLocationSettings();
  };

  const handleSelectChange = (signId, value) => {
    setSelectedGatekit((prev) => ({
      ...prev,
      [signId]: value
    }));
  };

  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium">Location Information</h3>
        <p className="text-sm text-muted-foreground">View information about your location.</p>
      </div>
      <Separator />
      <div className="max-w-4xl space-y-3 sm:space-y-8 text-left">
        <div>
          <Label>Location ID</Label>
          <Input disabled value={values.id} className="w-44 mt-2" />
        </div>
        <div>
          <Label>Location Signs</Label>

          <Table className="mt-2">
            <TableHeader>
              <TableRow>
                <TableHead className="w-[80px] px-0">Sign ID</TableHead>
                <TableHead className="w-[100px]">QR Code</TableHead>
                <TableHead>Phone Number</TableHead>
                {values.version === 3 && <TableHead>Landing Page</TableHead>}
                {values.version === 4 && <TableHead className="w-[150px]">Lane Type</TableHead>}
                {values.version === 4 && <TableHead className="w-[300px]">Gatekit</TableHead>}
              </TableRow>
            </TableHeader>
            <TableBody>
              {values.signs.map((sign) => {
                const qrURL = qrUrls.find((el) => el.obscuredSignId === sign.obscuredId)?.qrURL;
                const isEditing = editState[sign.signId];
                return (
                  <TableRow key={sign.signId}>
                    <TableCell className="px-0">{sign.signId}</TableCell>
                    <TableCell>
                      {qrURL ? (
                        <Button variant="link" className="px-0 py-0" onClick={() => window.open(qrURL, '_blank')}>
                          Link
                        </Button>
                      ) : (
                        ' - '
                      )}
                    </TableCell>
                    <TableCell>{(sign.phoneNumber ?? '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')}</TableCell>
                    {values.version === 3 && (
                      <TableCell>
                        <Button variant="link" className="px-0 py-0" onClick={() => window.open(sign.landingUrl, '_blank')}>
                          {(sign.landingUrl ?? '').replace('https://', '')}
                        </Button>
                      </TableCell>
                    )}
                    {values.version === 4 && <TableCell>{sign.type ?? ' - '}</TableCell>}
                    {values.version === 4 && (
                      <TableCell className="flex justify-between items-center">
                        <Select
                          value={selectedGatekit[sign.signId] ?? ''}
                          onValueChange={(value) => handleSelectChange(sign.signId, value)}
                          disabled={!isEditing || isSaving}>
                          <SelectTrigger className="w-[200px]">
                            <SelectValue placeholder="Select a gatekit" />
                          </SelectTrigger>

                          <SelectContent>
                            {/* Add Current Gatekit ID so we know its an option in the select component */}
                            {sign.gatekitId && (
                              <SelectItem value={sign.gatekitId} key={sign.gatekitId}>
                                {sign.laneName || sign.gatekitId}
                              </SelectItem>
                            )}
                            {particleDevices &&
                              particleDevices
                                .filter((device) => device.id !== sign.gatekitId)
                                .map((device) => (
                                  <SelectItem value={device.id} key={device.id}>
                                    {device.name}
                                  </SelectItem>
                                ))}
                          </SelectContent>
                        </Select>

                        <Button
                          className="ml-auto"
                          onClick={() => (isEditing ? handleSaveClick(sign.signId) : handleEditClick(sign.signId))}
                          disabled={isSaving || (isEditing && selectedGatekit[sign.signId] === '')}>
                          {isEditing ? 'Save' : 'Edit'}
                        </Button>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
}
