import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle
} from 'components/ui/alert-dialog';

export default function ConfirmDeleteScheduleDialog({ scheduleName, open, onClose, onSubmit }) {
  return (
    <AlertDialog open={open} onOpenChange={(isOpen) => !isOpen && onClose()}>
      <AlertDialogContent className="gap-0">
        <AlertDialogHeader>
          <AlertDialogTitle>Delete rate</AlertDialogTitle>
        </AlertDialogHeader>
        <p className="text-center mb-4">{scheduleName}</p>
        <p className="text-center text-sm text-muted-foreground">Please confirm you would like to delete this rate.</p>
        <p className="text-center text-sm text-muted-foreground mb-4">This action cannot be undone.</p>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={onClose}>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={onSubmit}>Continue</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
