import api from './api';

export const getAvailablePhoneNumbersAPI = async (latitude, longitude, city, state) =>
  api({ url: `/add-location/get-available-numbers?latitude=${latitude}&longitude=${longitude}&city=${city}&state=${state}`, method: 'get' });
export const getLocationIdAvailabilityAPI = async (locationId) =>
  api({ url: `/add-location/get-location-id-availability?locationId=${locationId}`, method: 'get' });
export const getStripeTaxRatesAPI = async () => api({ url: `/add-location/get-stripe-tax-rates`, method: 'get' });
export const getParticleDevicesAPI = async (locationId) => {
  const url = locationId ? `/add-location/get-particle-devices?locationId=${encodeURIComponent(locationId)}` : '/add-location/get-particle-devices';
  return api({ url, method: 'get' });
};
export const getStripeTaxRateAPI = async (id) => api({ url: `/add-location/get-stripe-tax-rate?id=${id}`, method: 'get' });
export const createStripeTaxRateAPI = async ({ payload }) => api({ url: '/add-location/create-stripe-tax-rate/', method: 'post', payload });
export const createNewLocationAPI = async ({ payload }) => api({ url: '/add-location/create-new-location/', method: 'post', payload });
