import { Button } from 'components/ui/button';
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from 'components/ui/dialog';
import FlatRateTypeForm from './FlatRateTypeForm';
import IntervalRateTypeForm from './IntervalRateTypeForm';
import VariableRateSliderForm from './VariableRateSliderForm';
import { formatFlatEventTitle, formatIntervalEventTitle, formatVariableEventTitle } from './helpers';

const dayjs = require('dayjs');

export default function EditRateTypeDialog({ open, onClose, event, onDeleteEvent, onSubmit, dayStartOffset }) {
  const startDayjs = event?.start ? dayjs(event?.start) : null;
  const endDayjs = event?.end ? dayjs(event?.end) : null;
  const selectDuration = endDayjs ? endDayjs.diff(startDayjs, 'minute') : 0;

  const formattedStartString = dayjs(event?.start).add(1, 'second').startOf('minute').add(dayStartOffset, 'minutes').format('dddd h:mmA');
  const formattedEndString = dayjs(event?.end).add(1, 'second').startOf('minute').add(dayStartOffset, 'minutes').format('dddd h:mmA');

  const onSubmitForm = (data) => {
    let newData = null;
    let newTitle = null;
    switch (data?.type) {
      case 'flat':
        newTitle = formatFlatEventTitle(data.rate);
        newData = { rate: data.rate };
        break;
      case 'interval':
        newTitle = formatIntervalEventTitle(data);
        newData = { rate: data.rate, interval: data.interval, dailyMax: data.dailyMax };
        break;
      case 'variable':
        newTitle = formatVariableEventTitle(data.rates);
        newData = { rates: data.rates };
        break;
      default:
        console.log('Unexpected rate type submitted from form');
        onClose();
        break;
    }

    onSubmit({
      oldEvent: event,
      newStart: event.start,
      newEnd: event.end,
      newTitle,
      newData
    });
  };

  return (
    <Dialog open={open} onOpenChange={(isOpen) => !isOpen && onClose()}>
      <DialogContent className="max-w-[425px] max-sm:max-w-[350px]">
        <DialogHeader>
          <DialogTitle>Edit Rate Type</DialogTitle>
          <DialogDescription>
            {formattedStartString} - {formattedEndString}
          </DialogDescription>
        </DialogHeader>

        {event?.type === 'flat' && (
          <div>
            <p className="text-sm mb-2 mt-2">Specify a single flat rate the customer pays per park.</p>
            <FlatRateTypeForm onSubmit={onSubmitForm} defaultValues={event?.data} />
          </div>
        )}

        {event?.type === 'interval' && (
          <div>
            <p className="text-sm mb-2 mt-2">Specify a rate the customer pays every certain number of minutes.</p>
            <IntervalRateTypeForm onSubmit={onSubmitForm} defaultValues={event?.data} />
          </div>
        )}

        {event?.type === 'variable' && (
          <div>
            <p className="text-sm mb-2 mt-2">Specify custom rates depending on parking duration.</p>
            <VariableRateSliderForm onSubmit={onSubmitForm} selectDuration={selectDuration} defaultValues={event?.data?.rates} />
          </div>
        )}

        <DialogFooter className="max-sm:hidden justify-between justify-between space-x-2">
          <Button variant="destructive" onClick={() => onDeleteEvent(event?.id)}>
            Delete
          </Button>
          <Button type="submit" form={`${event?.type}-rate-type-form`}>
            Save Changes
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
