import { Button } from 'components/ui/button';
import { Checkbox } from 'components/ui/checkbox';
import { Drawer, DrawerClose, DrawerContent, DrawerDescription, DrawerFooter, DrawerHeader, DrawerTitle } from 'components/ui/drawer';
import { Table, TableBody, TableCell, TableRow } from 'components/ui/table';
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip';
import { Info, PlusCircle, Trash2Icon } from 'lucide-react';

import { useEffect, useState } from 'react';
import { DeviceFrameset } from 'react-device-frameset';
import 'react-device-frameset/styles/marvel-devices.min.css';

const uuid = require('uuid');

const MAX_PRICE_DESCRIPTORS = 10;
const MAX_DESCRIPTOR_LENGTH = 40;
const MAX_AMOUNT_LENGTH = 7;

export const defaultInitialPriceDescriptors = [
  { descriptor: 'First hour', amount: '$0.01', id: uuid.v4() },
  { descriptor: 'Daily Max', amount: '$0.25', id: uuid.v4() },
  { descriptor: '', amount: '', id: uuid.v4() }
];

export default function SetPriceDescriptorsDialog({ open, onClose, onSubmit, locationName, priceDescriptors, setPriceDescriptors, locationDetails }) {
  const [isAcknowledged, setIsAcknowledged] = useState(false);

  useEffect(() => {
    setIsAcknowledged(false);
  }, [open]);

  const deletePriceDescriptor = (id) => {
    setPriceDescriptors((old) => old.filter((el) => el.id !== id));
  };

  const editPriceDescriptor = (id, value) => {
    if (value.length <= MAX_DESCRIPTOR_LENGTH) {
      setPriceDescriptors((old) => old.map((el) => (el.id === id ? { ...el, descriptor: value } : el)));
    }
  };

  const editPriceDescriptorAmount = (id, value) => {
    if (value.length <= MAX_AMOUNT_LENGTH) {
      setPriceDescriptors((old) => old.map((el) => (el.id === id ? { ...el, amount: value } : el)));
    }
  };

  const submitClicked = () => {
    const filteredDescriptors = priceDescriptors.filter((el) => el.descriptor || el.amount);
    onSubmit(filteredDescriptors);
  };

  const formatGracePeriod = (minutes) => {
    if (!minutes) return '0m';
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours ? `${hours}h` : ''}${remainingMinutes ? ` ${remainingMinutes}m` : ''}`;
  };

  const formatServiceFee = (fee) => (fee.flatAmount !== null ? `$${(fee.flatAmount / 100).toFixed(2)}` : `${fee.percentage ?? 0}%`);

  return (
    <Drawer open={open} onOpenChange={(isOpen) => !isOpen && onClose()}>
      <DrawerContent>
        <DrawerHeader className="mb-4">
          <DrawerTitle>Confirm Rate Sign</DrawerTitle>
          <DrawerDescription>This is what is shown when you scan the QR code.</DrawerDescription>
          <div className="w-[764px] mx-auto">
            <div className="flex items-center justify-center bg-red-200 rounded-lg p-3">
              <Info className="h-4 w-4 mr-3" />
              <p className="text-sm ">Make sure these match any changes you made to your rates!</p>
            </div>
          </div>
        </DrawerHeader>
        <div className="flex justify-center mb-6">
          <div className="w-96">
            <p className="text-center font-medium text-muted-foreground text-sm">Pricing</p>
            <Table>
              <TableBody>
                {priceDescriptors.map((el) => (
                  <TableRow key={el.id}>
                    <TableCell>
                      <input
                        placeholder="eg. Evenings (6p - 6a)"
                        value={el.descriptor}
                        onChange={(event) => editPriceDescriptor(el.id, event.target.value)}
                        className="peer h-full w-full focus:border-b border-blue-gray-200 bg-transparent pt-1.5 pb-1.5 text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-gray-900 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                      />
                    </TableCell>
                    <TableCell className="text-right w-[70px]">
                      <input
                        placeholder="$10.00"
                        value={el.amount}
                        onChange={(event) => editPriceDescriptorAmount(el.id, event.target.value)}
                        className="peer h-full w-full focus:border-b border-blue-gray-200 bg-transparent pt-1.5 pb-1.5 text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-gray-900 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                      />
                    </TableCell>
                    <TableCell className="text-right w-[30px]">
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Button className="justify-center " variant="ghost" size="icon" onClick={() => deletePriceDescriptor(el.id)}>
                            <Trash2Icon className="h-4 w-4" />
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>Delete Row</TooltipContent>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <div className="flex justify-center">
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant="ghost"
                    size="icon"
                    className="align-center"
                    disabled={priceDescriptors.length >= MAX_PRICE_DESCRIPTORS}
                    onClick={() => setPriceDescriptors((old) => [...old, { descriptor: '', amount: '', id: uuid.v4() }])}>
                    <PlusCircle className="h-6 w-6" />
                    <span className="sr-only">Add Row</span>
                  </Button>
                </TooltipTrigger>
                <TooltipContent>Add Row</TooltipContent>
              </Tooltip>
            </div>
          </div>
          <div className="ml-20 h-[440px] w-[280px] relative parent ">
            <div className="absolute top-[-220px] left-[-40px] h-0 w-96 left-0">
              <DeviceFrameset device="iPhone 8" color="silver" zoom={0.5}>
                <div className="p-4 h-full proparcs-landing-page-styles">
                  <p className="text-center text-3xl font-bold mb-2">Welcome to the</p>
                  <p className="text-center text-3xl font-bold">{locationName}</p>
                  <div className="flex items-center mt-10">
                    <div className="flex-grow border-b-2 border-white/30"></div>
                    <div className="mx-1 font-medium text-2xl ">Pricing</div>
                    <div className="flex-grow border-b-2 border-white/30"></div>
                  </div>
                  <div className="relative w-full overflow-auto">
                    <table className="w-full text-xl">
                      <tbody>
                        {locationDetails?.gracePeriod && (
                          <tr className="border-b-2 border-white/30">
                            <td className="py-2 font-semibold">Grace Period</td>
                            <td className="text-right w-[30px] font-semibold">{formatGracePeriod(locationDetails.gracePeriod)}</td>
                          </tr>
                        )}
                        {priceDescriptors
                          .filter((el) => el.descriptor || el.amount)
                          .map((el) => (
                            <tr key={el.id} className="border-b-2 border-white/30">
                              <td className="py-2 font-semibold">{el.descriptor}</td>
                              <td className="text-right w-[30px] font-semibold">{el.amount}</td>
                            </tr>
                          ))}
                        {locationDetails?.serviceFees &&
                          locationDetails.serviceFees.map((fee) => (
                            <tr key={fee.id} className="border-b-2 border-white/30">
                              <td className="py-2 font-semibold">{fee.descriptor}</td>
                              <td className="text-right w-[30px] font-semibold">{formatServiceFee(fee)}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </DeviceFrameset>
            </div>
          </div>
        </div>

        <div className="w-[764px] mx-auto">
          <DrawerFooter>
            <div className="flex items-center space-x-2 mb-2">
              <Checkbox id="acknowledge" onCheckedChange={setIsAcknowledged} value={isAcknowledged} />
              <label htmlFor="acknowledge" className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                I acknowledge this rate sign accurately reflects the rates I submitted on the rate editor
              </label>
            </div>
            <Button onClick={submitClicked} disabled={!isAcknowledged}>
              Submit
            </Button>
            <DrawerClose asChild>
              <Button variant="outline" onClick={onClose}>
                Cancel
              </Button>
            </DrawerClose>
          </DrawerFooter>
        </div>
      </DrawerContent>
    </Drawer>
  );
}
